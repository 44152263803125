import { createStore } from 'vuex'

export const dataUser = createStore({
  state: () => {
    return {
      company: {
        name: undefined,
        cnpj: undefined,
        city: undefined,
        address: undefined,
        country: undefined,
        number: undefined,
        photo: undefined
      },
      imgPath: undefined,
      configs: {
        openingHours: "08:00",
        closingTime: undefined,
        openOnWeekends: true,
        closesForLunch: true,
        hourForLunch: undefined,
        returnForLunch: undefined,
        saturdayHour: "13:00",
        serviceInterval: 15,
        periods: [],
        unavailableTimes: [],
      },
    }
  },
  getters: {
    getopenOnWeekends: (state) => state.configs.openOnWeekends,
    getopeningHours: (state) => state.configs.openingHours,
    getclosingTime: (state) => state.configs.closingTime,
    getclosesForLunch: (state) => state.configs.closesForLunch,
    gethourForLunch: (state) => state.configs.hourForLunch,
    getreturnForLunch: (state) => state.configs.returnForLunch,
    getsaturdayHour: (state) => state.configs.saturdayHour,
    getserviceInterval: (state) => state.configs.serviceInterval,
    getperiods: (state) => state.configs.periods,
    getunavailableTimes: (state) => state.configs.unavailableTimes,
  },
  mutations: {
    reset (state) {
      state.company.name = undefined
      state.company.cnpj = undefined
      state.company.city = undefined
      state.company.address = undefined
      state.company.country = undefined
      state.company.number = undefined
      state.company.photo = undefined
      state.imgPath = undefined
      
      // configs
      state.configs.openingHours = "08:00",
      state.configs.closingTime = undefined,
      state.configs.openOnWeekends = true,
      state.configs.closesForLunch = true,
      state.configs.hourForLunch = undefined,
      state.configs.returnForLunch = undefined,
      state.configs.saturdayHour = "13:00",
      state.configs.serviceInterval = 15,
      state.configs.periods = [],
      state.configs.unavailableTimes = []
    },
    setConfigFields(state, value) {
      state.configs.openingHours = value.openingHours,
      state.configs.closingTime = value.closingTime,
      state.configs.openOnWeekends = value.openOnWeekends,
      state.configs.closesForLunch = value.closesForLunch,
      state.configs.hourForLunch = value.hourForLunch,
      state.configs.returnForLunch = value.returnForLunch,
      state.configs.saturdayHour = value.saturdayHour,
      state.configs.serviceInterval = value.serviceInterval,
      state.configs.periods = value.periods,
      state.configs.unavailableTimes = value.unavailableTimes
    }
  },
  actions: {
    setFieldConfigs({ commit }, data) {
      commit('setConfigFields', data)
    }
  },
})
