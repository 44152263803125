<template>
  <div>
    <PageTitle> Gestão de Custos </PageTitle>
    <ContainerVue>
      <Tabs>
        <template #firstTab>
          <TheLineChartVue
            :key="movimentData"
            :labels="months"
            :dataMoviment="movimentData"
            :dataCosts="costsData"
            movimentColor="#009285"
            costColor="#D98888"
          />

          <TotalAmount
            :label="'Total de entrada'"
            :color="'text-[#009285]'"
            :value="store.getters.getMovimentTotal"
          />
          <TotalAmount
            :label="'Total de saída'"
            :color="'text-[#b45a5a]'"
            :value="store.getters.getTotalCosts"
            :is_cost="true"
          />

          <section class="mt-5" />

          <Filter />

          <section v-if="store.state.filters.applyeds.length > 0">
            <p class="pt-2">
              Filtros:
            </p>

            <div v-if="store.state.filters.applyeds?.length > 0" class="mb-2 w-full p-1">
              <div
                class="inline-flex flex-wrap"
                v-for="(item, index) in store.state.filters.applyeds"
                :key="index"
              >
                <div
                  class="ml-1 mt-2 p-2 border border-gray-200 rounded bg-gray-50 inline-block"
                >
                  {{ item.key }}: {{ item.value }}
                </div>

                <button
                  class="flex items-center justify-center bg-red-500 text-white p-2 rounded-full -ml-4 -mt-1 h-5 w-5 "
                  @click="removeItem(item.key)"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </section>

          <Paginate :items="store.state.items" :itemsPerPage="10" @reload="reloadData">
            <thead>
              <tr>
                <TableHeadFieldVue>
                  <span class="mb-1.5"> Registrado por </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Descrição </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Valor </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Tipo </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Categoria </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Lançado em </span>
                </TableHeadFieldVue>

                <TableHeadFieldVue>
                  <span class="mb-1.5"> Pagamento via </span>
                </TableHeadFieldVue>
              </tr>
            </thead>
          </Paginate>
        </template>

        <template #secondTab>
          <Form :descriptionTitle="'Registre uma nova saída'">
            <CreateButton
              @click="newCost"
              :label="'Lançar'"
              :icon="'fa-solid fa-file-import'"
            />
          </Form>
        </template>
      </Tabs>
    </ContainerVue>
  </div>
</template>

<script setup>
import TableHeadFieldVue from '@/components/Tables/TableHeadField.vue'
import TotalAmount from './components/utils/TotalAmount.vue'
import TheLineChartVue from './components/TheLineChart.vue'
import PageTitle from '@/components/Titles/PageTitle.vue'
import ContainerVue from '@/components/Container.vue'
import CostService from './services/costs.service'
import Filter from './components/utils/Filter.vue'
import Paginate from './components/Paginate.vue'
import Tabs from './components/TabsVue.vue'
import Form from './layouts/Form.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { useToast } from 'vue-toastification'
import { onBeforeMount, onMounted, ref } from 'vue'
import { dataCosts } from './store'
import { storeApp } from '@/store'
import * as yup from '@/validator'
import moment from 'moment'

const toast = useToast()
const appStore = storeApp
const store = dataCosts

const months = ref([
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
])
const movimentData = ref([])
const costsData = ref([])

const schema = yup.object().shape({
  form: yup.object().shape({
    name: yup.string().required('É necessário informar a descrição').label('Descrição'),
    amount: yup.string().required('Informe um valor válido').label('Valor')
  })
})

onMounted(async () => {
  appStore.state.loading = true
  await Promise.all([
    getChartData(),
    getCosts()
  ])
  appStore.state.loading = false
})

async function reloadData () {
  appStore.state.loading = true
  await Promise.all([
    getChartData(),
    getCosts()
  ])
  appStore.state.loading = false
}

async function getChartData () {
  store.commit('reset')
  await CostService.getDataChart()
    .then(res => {
      costsData.value = Object.values(res.data?.costs).map(value => String(value))

      movimentData.value = Object.values(res.data?.moviment).map(value => String(value))
    }).catch(err => {
      console.log(err)
    })
}

async function getCosts () {
  await CostService.getDataCosts()
    .then(res => {
      if (res.data.length > 0) {
        res.data[0].forEach(el => {
          store.state.items.push(
            {
              id: el.id,
              registered_by: el.user.name,
              cost_name: el.name,
              cost_amount: el.amount,
              type: JSON.parse(el.type)?.name ?? 'não informado',
              category: JSON.parse(el.category)?.name ?? 'não informado',
              payment: JSON.parse(el.payment)?.name ?? 'não informado',
              date: moment(el.date).format('DD/MM/YY')
            }
          )
        })
        if (res.data[1].length > 0) {
          store.state.moviments = res.data[1].map(el => ({
            ...el,
            date: moment(el.created_at).format('DD/MM/YY')
          }))
        }
      }
    }).catch(err => {
      toast('Algo deu errado ao buscar custos', {
        toastClassName: 'toast-error'
      })
    })
}

async function newCost () {
  appStore.state.loading = true
  const validate = (await validateFormField(schema, store.state))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  delete store.state.form.user

  if (store.state.form.date == undefined || store.state.form.date == '') { store.state.form.date = new Date() }

  await CostService.registerCost(store.state.form)
    .then(res => {
      if (res.data.success) { reloadData() }
      toast(res.data.success, {
        toastClassName: 'toast-success'
      })
    }).catch(err => {
      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    }).finally(() => {
      appStore.state.loading = false
    })
}

function removeItem (key) {
  store.dispatch('removeSelectedFilter', key)
}

</script>
