<template>
  <section v-if="dias?.length > 0 && hasSchedules" :key="hasSchedules">
    <ContainerVue  v-if="hasSchedules" :key="hasSchedules">
      <div class="agendamentos-container flex flex-col items-center">
        <div
          class="dias-semana flex space-x-2 mb-4 m-2 mt-1 p-2 overflow-x-auto max-w-[100%]"
        >
          <div
            v-for="(dia, index) in dias"
            :key="index"
            :class="[
              'dia flex flex-col items-center justify-center min-w-[5rem] w-20 h-24 rounded-md cursor-pointer',
              dia.selecionado
                ? 'bg-white shadow-md border border-gray-300 transform scale-105'
                : 'bg-gray-200',
              'transition duration-200 ease-in-out transform hover:scale-105',
            ]"
            @click="selecionarDia(index)"
          >
            <span
              v-if="dia.agendamentos > 0 && !dia.selecionado"
              class="absolute top-0 right-0 mt-2 mr-2 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white border text-sm font-semibold rounded-full w-7 h-7 flex items-center justify-center"
            >
              {{ dia.agendamentos }}
            </span>

            <span class="data font-semibold text-xl mb-2">{{ dia.data }}</span>
            <span class="dia-semana text-lg">{{ dia.diaSemana.slice(0, 3) }}</span>
            <small class="text-gray-400">{{ dia.mes.slice(0, 3) }}</small>
          </div>
        </div>

        <!-- cards -->
        <div class="informacoes flex space-x-4">

          <!-- serviços -->
          <div class="bg-[#F5F3E4] border-[#B4955A] card flex flex-col items-center justify-center border rounded-md px-4 py-2 text-center text-base md:text-lg">
            <span class="quantidade font-bold text-[#B4955A]">
              {{ agendamentos }}
            </span>
            <span class="text-[#B4955A]">Agendamentos</span>
          </div>

          <!-- faturamento -->
          <div class="bg-[#e5f4f3] card flex flex-col items-center justify-center border border-[#009285] rounded-md px-4 py-2 text-center text-base md:text-lg">
            <span class="valor font-bold text-[#009285]">
              {{ faturamento }}
            </span>
            <span class="text-[#009285]">Faturamento previsto</span>
          </div>
        </div>

      </div>
    </ContainerVue>

    <ContainerVue :key="clients">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2" :key="informations.getModalSchedules">
        <ClientCard
          v-for="(item, index) in clients"
          :key="index"
          :horario="item.horario"
          :cliente="item.cliente"
          :servico="item.servico"
          :preco="item.preco"
          :duracao="item.duracao"
          :id="item.id"
          :item="item"
          @register="registerService"
          @remove="removeSchedule"
          @open-modal="openModal(item)"
        />
      </div>
    </ContainerVue>

    <!-- modal -->
    <BaseModal :isOpen="isModalOpen" @update:isOpen="closeModal">
      <MoreInformationModal :selectedItem="dataModal" />
    </BaseModal>
  </section>

  <!-- sem dados -->
  <div v-else>
    <NoDataAnim
      class="mb-6"
      :width="300"
      :height="300"
      :label="'Ainda não há agendamentos'"
    />
  </div>
</template>

<script setup>
import MoreInformationModal from '../layouts/MoreInformationModal.vue'
import ScheduleService from '../services/schedule.service'
import BaseModal from '@/components/Modals/BaseModal.vue'
import ClientCard from './../components/ClientCard.vue'
import ContainerVue from '@/components/Container.vue'
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useToast } from 'vue-toastification'
import { dataSchedule } from '../store'
import { storeApp } from '@/store'
import NoDataAnim from '@/components/Lotties/NoDataAnim.vue'

let intervalId
let metas = null

const toast = useToast()
const store = dataSchedule
const informations = dataSchedule.getters
const agendamentos = ref(0)
const faturamento = ref(0)
const modalData = ref()

// TODO: fazer fluxo auxiliar para passar todos os agendamentos para movimentações/cortes feitos

const dataModal = computed(() => modalData.value)
const userAuthenticated = computed(() => storeApp.getters.getUserAuthenticate)
const hasSchedules = computed(() => dataSchedule.getters.hasSchedules)
const isModalOpen = computed(() => dataSchedule.getters.getModalSchedulesState)
const dias = computed(() => informations.getSchedules)
const clients = computed(() => {
  const selectedDay = dias.value.find(dia => dia.selecionado)

  if (selectedDay) {
    agendamentos.value = selectedDay?.agendamentos

    // se tiver apenas um agendamento não é feito o calculo
    if (agendamentos.value > 1) {
      const total = ref(0)
      faturamento.value = selectedDay?.collect.forEach((sum, service) => {
        let price = sum?.preco

        if (typeof price === 'string') {
          price = price.replace(/[^0-9,]/g, '').replace(',', '.')
          price = parseFloat(price)
        }

        total.value = total.value + price
      })

      faturamento.value = total.value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    } else {
      faturamento.value = selectedDay?.collect[0]?.preco.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    }

    return selectedDay.collect || []
  }
})

async function registerService(item) {
  // storeApp.state.loading = true

  let products = [];
  if (Array.isArray(item.product_id)) {
    const serviceNames = item.servico.split(", ");

    item.product_id.forEach((id, index) => {
        products.push({
          name: serviceNames[index] || "Serviço desconhecido",
          value: id,
          amount: null,
          price: `R$ ${item.preco.toFixed(2).replace('.', ',')}` 
        });
    });
  } else {
    products.push({
      name: item.servico,
      value: item.product_id,
      amount: null, 
      price: `R$ ${item.preco.toFixed(2).replace('.', ',')}` 
    });
  }

  const confirm = window.confirm('Deseja registrar esse serviço?');
  if (confirm) { 
    await ScheduleService.registerService({ 
      products, 
      done: true, 
      id: item.id, 
      client_name: item.cliente 
    }).then(res => {
      if (res.data.success) {
        dataSchedule.commit('clearSchedules')
        getSchedules()
        closeModal()

        toast(res.data.message, {
          toastClassName: 'toast-success'
        })

        storeApp.state.loading = false
      }
    }).catch(err => {
      storeApp.state.loading = false

      if (err?.response?.status && err?.response?.status === 422) {
        return toast(err.response.data.message, {
          toastClassName: 'toast-error'
        })
      }

      if (err?.response?.status && err?.response?.status === 400) {
        return toast(err.response.data.message, {
          toastClassName: 'toast-error'
        })
      }

      return toast('Algo deu errado ao cadastrar', {
        toastClassName: 'toast-error'
      })
    })
  }
  storeApp.state.loading = false
}

async function removeSchedule(id) {
  storeApp.state.loading = true
  
  const confirm = window.confirm('Deseja realmente remover esse agendamento?');
  if (confirm) { 
    await ScheduleService.removeSchedule(id)
    .then(res => {
      console.log(res);
      
      if(res.data.success) {
          dataSchedule.commit('clearSchedules')
          getSchedules()
          closeModal()

          toast('Agendamento removido com sucesso', {
            toastClassName: 'toast-success'
          })
          
          storeApp.state.loading = false
        }
      }).catch(err => {
        storeApp.state.loading = false

        if (err?.response?.status && err?.response?.status === 422) {
          return toast(err.response.data.message, {
            toastClassName: 'toast-error'
          })
        }

        if (err?.response?.status && err?.response?.status === 400) {
          return toast(err.response.data.message, {
            toastClassName: 'toast-error'
          })
        }

        return toast('Algo deu errado ao cadastrar', {
          toastClassName: 'toast-error'
        })

      })
  }

  storeApp.state.loading = false
}

function openModal (val) {
  dataSchedule.commit('activateModalSchedules')
  modalData.value = val
}

function closeModal () {
  dataSchedule.commit('disableModalSchedules')
}

function selecionarDia (index = 0) {
  if (index >= 0 && index < dias.value.length) {
    dias.value.forEach((dia, i) => (dia.selecionado = i === index))
  } else {
    dias.value.forEach((dia, i) => (dia.selecionado = i === 0))
  }
}

// busca todos os agendamentos
async function getSchedules () {
  await ScheduleService.getAllSchedules(metas)
    .then(res => {
      const response = res.data
      if (response.success) {
        if (response.data.length !== 0) { dataSchedule.commit('setHasSchedules', true) }
        store.dispatch('updateSchedulesFields', response.data)
      }
    }).catch(err => {
      console.log(err)
    })
}

const fetchSchedules = async () => {
  if (userAuthenticated) {
    try {
      const response = await ScheduleService.getAllSchedules(metas)

      if (response.data.success) {
        if (response.data.data.length !== 0) {
          dataSchedule.commit('setHasSchedules', true)
          store.dispatch('updateSchedulesFields', response.data.data)
        }
      }
    } catch (err) {
      console.error('Erro ao buscar agendamentos:', err)
    }
  } else {
    dataSchedule.commit('clearSchedules')
    clearInterval(intervalId)
  }
}

function getPermission () {
  const userPermissions = localStorage.getItem('__permissions')

  if (userPermissions) {
    const permissionsArray = userPermissions.split(',')

    if (permissionsArray.includes('view_schedulings')) {
      metas = 'view_schedulings' // Prioriza "view_schedulings"
    } else if (permissionsArray.includes('view_my_schedulings')) {
      metas = 'view_my_schedulings' // Se não houver "view_schedulings", retorna "view_my_schedulings"
    }
  }
}

onMounted(async () => {
  storeApp.state.loading = true
  getPermission()
  await getSchedules()
  selecionarDia()
  storeApp.state.loading = false

  // busca os novos agendamntos se houver
  intervalId = setInterval(fetchSchedules, 10000)
})

onUnmounted(() => {
  dataSchedule.commit('clearSchedules')
  clearInterval(intervalId)
})

</script>
