import SetCode from '@/modules/login/layout/SetCode.vue'
import SignIn from '@/modules/login/layout/SignInComponent.vue'
import SignUp from '@/modules/login/layout/SignUpComponent.vue'
import ForgotPassword from '@/modules/login/layout/ForgotPassword.vue'
import LoginView from '@/modules/login/LoginView.vue'
import { dataAuth } from '../store'
import { markRaw } from 'vue'

export const loginRoutes = [
  {
    path: '/login',
    alias: '/login',
    name: 'login',
    component: LoginView,
    children: [
      {
        path: '/sign-in',
        name: 'login-sign-in',
        component: SignIn,
        beforeEnter: (to, from, next) => {
          dataAuth.dispatch('updateComponentActive', markRaw(SignIn))
          next()
        }
      },
      {
        path: '/sign-up/:indicatedBy?',
        name: 'login-sign-up',
        component: SignUp,
        beforeEnter: (to, from, next) => {
          dataAuth.dispatch('updateComponentActive', markRaw(SignUp))
          next()
        }
      },
      {
        path: '/forgot',
        name: 'login-forgot',
        component: ForgotPassword,
        beforeEnter: (to, from, next) => {
          dataAuth.dispatch('updateComponentActive', markRaw(ForgotPassword))
          next()
        }
      },
      {
        path: '/confirm-code',
        name: 'confirm-code',
        component: SetCode
      }
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    alias: '/logout'
  }
]
