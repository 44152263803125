<template>
  <div class="flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <!-- block -->
        <div class="md:pl-8 md:pr-8 mb-6">
          <div class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
           
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ titleFirstContainer }}
              </p>
              <p class="text-gray-500">
                {{ descriptionFirstContainer }}
              </p>
            </div>

            <!-- horarios do estabelecimento -->
            <div class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                <!-- abertura -->
                <div class="md:col-span-3">
                  <BaseInput
                    v-model="store.state.configs.openingHours"
                    name="configs.openingHours"
                    :info="'Horario de abertura da sua barbearia'"
                    label="Abertura"
                    type="time"
                    :required-field="true"
                    :center="true"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.configs.openingHours = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="configs.openingHours"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- fechamento -->
                <div class="md:col-span-2">
                  <BaseInput
                    v-model="store.state.configs.closingTime"
                    name="configs.closingTime"
                    :info="'Horario de encerramento'"
                    label="Encerramento"
                    type="time"
                    :required-field="true"
                    :center="true"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.configs.closingTime = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- Almoço -->
                <div class="md:col-span-2">
                  <label class="flex pl-1 text-xs text-[#2b3038]">
                    Fecha para almoço?
                  </label>
                  <div class="h-10 flex rounded items-center mt-1">
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :initialValue="store.state.configs.closesForLunch ? '1' : '0'"
                      :data="louncHours"
                      @selected-data="
                        (val) => {
                          if (val.name == 'SIM')
                            store.state.configs.closesForLunch = true;
                          else store.state.configs.closesForLunch = false;
                        }
                      "
                    />
                  </div>
                </div>

                <div class="md:col-span-3">

                  <!-- Almoço horarios -->
                  <Transition mode="out-in">
                    <div class="lg:col-span-2 -mt-1" v-if="store.state.configs.closesForLunch">
                      <div class="grid gap-2 gap-y-2 text-sm grid-cols-1 md:grid-cols-2">
                        <!-- Almoço -->
                        <div class="md:col-span-1">
                          <BaseInput
                            v-model="store.state.configs.hourForLunch"
                            name="configs.hourForLunch"
                            :info="'Qual horario?'"
                            label="Almoço"
                            type="time"
                            :required-field="false"
                            :center="true"
                            :activeDefault="true"
                          >
                            <template #append>
                              <CleanInputButton
                                @click="store.state.configs.hourForLunch = undefined"
                              />
                            </template>
                          </BaseInput>
                        </div>

                        <!-- Retorno do almoço -->
                        <div class="md:col-span-1">
                          <BaseInput
                            v-model="store.state.configs.returnForLunch"
                            name="configs.returnForLunch"
                            :info="'Retorno do almoço'"
                            label="Retorno"
                            type="time"
                            :required-field="false"
                            :center="true"
                            :activeDefault="true"
                          >
                            <input type="" />
                            <template #append>
                              <CleanInputButton
                                @click="store.state.configs.returnForLunch = undefined"
                              />
                            </template>
                          </BaseInput>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>

                <!-- trabalha aos fins de semana -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                    Aberto aos fins de semana?
                  </label>
                  <div class="h-10 flex rounded items-center mt-1">
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :initialValue="store.state.configs.openOnWeekends ? '1' : '0'"
                      :data="types"
                      @selected-data="
                        (val) => {
                          if (val.name == 'SIM')
                            store.state.configs.openOnWeekends = true
                          else if (val.name == 'APENAS SÁBADO') {
                            store.state.configs.openOnWeekends = true
                          } else {
                            store.state.configs.openOnWeekends = false
                          }
                        }
                      "
                    />
                  </div>
                </div>

                <!-- horarios de sábado -->
                <Transition mode="out-in">
                  <div class="lg:col-span-2 -mt-1" v-if="store.state.configs.openOnWeekends">
                    <div class="grid gap gap-y-2 text-sm grid-cols-1 md:grid-cols w-full">
                      
                      <!-- horarios de sábado -->
                      <div class="md:col-span-1">
                        <BaseInput
                          v-model="store.state.configs.saturdayHour"
                          name="configs.saturdayHour"
                          :info="'Até que horas?'"
                          label="Almoço"
                          type="time"
                          :required-field="false"
                          :center="true"
                          :activeDefault="true"
                        >
                          <template #append>
                            <CleanInputButton
                              @click="store.state.configs.saturdayHour = undefined"
                            />
                          </template>
                        </BaseInput>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>

        <!-- agendamentos -->
        <div class="md:pl-8 md:pr-8">
          <div class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ titleSecondContainer }}
              </p>
              <p class="text-gray-500">
                {{ descriptionSecondContainer }}
              </p>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mb-4"
              >
                <!-- intervalo entre serviços -->
                <div class="md:col-span-3">
                  <BaseInput
                    v-model="store.state.configs.serviceInterval"
                    name="configs.serviceInterval"
                    :info="'Intervalo minimo entre serviços'"
                    label="Intervalo entre serviços"
                    :type="'number'"
                    :required-field="true"
                    :center="true"
                    :activeDefault="true"
                    :min="10"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.configs.serviceInterval = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>
              </div>

              <div class="pt-2 pb-2 max-h-[60vh] overflow-y-auto">
                <!-- Botões de períodos -->
                <div class="flex justify-around bg-gray-200 p-4 -mt-2 text-white rounded-md">
                  <button
                    v-for="period in periods"
                    :key="period"
                    :class="[
                      'px-4 py-2 rounded font-semibold w-[30%]',
                      selectedPeriod === period
                        ? 'bg-white text-[#2b3038] font-bold'
                        : 'text-[#2b3038] bg-gray-200',
                    ]"
                    @click="selectPeriod(period)"
                  >
                    {{ period }}
                  </button>
                </div>

                <!-- Botões de horários -->
                <div
                  class="p-4 max-h-[65vh] overflow-y-auto grid grid-cols-3 gap-4"
                >
                  <div
                    v-for="time in filteredTimes"
                    :key="time"
                    class="relative flex items-center justify-center p-4 rounded-lg font-semibold cursor-pointer border hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all text-[#2b3038]"
                    :class="isSelected(time) ? 'bg-gray-200' : 'bg-white'"
                    @click="toggleSelection(time)"
                  >
                    <!-- Ícone dinâmico -->
                    <button
                      class="absolute top-[-10px] right-[-10px] bg-gray-200 text-[#2b3038] rounded-full w-6 h-6 flex items-center justify-center text-sm"
                    >
                      <i
                        :class="
                          isSelected(time) ? 'fas fa-eye-slash' : 'fas fa-eye'
                        "
                      ></i>
                    </button>

                    <!-- Texto do horário -->
                    <span>
                      {{ time }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2 mb-1" />

        <div>
          <slot />
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from "./../component/CleanInputButton.vue";
import SimpleSelect from "@/components/Select/SimpleSelect.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ref, computed, onMounted, watch } from "vue";
import { dataUser } from "../store/";

// Props
defineProps({
  titleFirstContainer: { type: String, required: false },
  descriptionFirstContainer: { type: String, required: false },
  titleSecondContainer: { type: String, required: false },
  descriptionSecondContainer: { type: String, required: false },
});

const store = dataUser

// Estado adicional
const types = [
  { name: "APENAS SÁBADO", value: "1" },
  { name: "NÃO", value: "0" },
];
const periods = ["Manhã", "Tarde", "Noite"];
const louncHours = [
  {
    name: "SIM",
    value: "1",
  },
  {
    name: "NÂO",
    value: "0",
  },
];
const selectedPeriod = ref("Manhã");
// const selectedTimes = ref(Array.isArray(dataUser.getters.getunavailableTimes) ? dataUser.getters.getunavailableTimes : []);
const selectedTimes = ref(dataUser.getters.getunavailableTimes || []);

// Computed para horários filtrados
const filteredTimes = computed(() => store.state.configs.periods[selectedPeriod.value]);

// Funções auxiliares
const timeToMinutes = (time) => {
  if (!time || typeof time !== "string" || !time.includes(":")) {
    console.error(`Invalid time format: ${time}`);
    return 0;
  }
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60).toString().padStart(2, "0");
  const mins = (minutes % 60).toString().padStart(2, "0");
  return `${hours}:${mins}`;
};

// Ajusta os horários de funcionamento dinamicamente
const adjustBusinessHours = () => {
  const opening = store.state.configs.openingHours;
  const closing = store.state.configs.closingTime;
  const closesForLunch = store.state.configs.closesForLunch;
  const lunchStart = store.state.configs.closesForLunch ? store.getters.gethourForLunch : '00:00';
  const lunchEnd = store.state.configs.closesForLunch ? store.getters.getreturnForLunch : '00:00';
  let interval = parseInt(store.state.configs.serviceInterval, 10);

  if (interval < 10) interval = 10;

  const periods = {
    Manhã: [],
    Tarde: [],
    Noite: [],
  };

  const unavailableTimes = [];

  let currentTime = timeToMinutes(opening);
  const endTime = timeToMinutes(closing);

  while (currentTime < endTime) {
    const currentTimeStr = minutesToTime(currentTime);

    // Verifica se o horário está no intervalo de almoço
    if (
      closesForLunch &&
      currentTime >= timeToMinutes(lunchStart) &&
      currentTime < timeToMinutes(lunchEnd)
    ) {
      // Adiciona horários de almoço ao array de horários selecionados
      unavailableTimes.push(currentTimeStr);
    } else {
      // Adiciona aos períodos correspondentes
      if (currentTime < timeToMinutes("12:00")) {
        periods.Manhã.push(currentTimeStr);
      } else if (currentTime < timeToMinutes("16:00")) {
        periods.Tarde.push(currentTimeStr);
      } else {
        periods.Noite.push(currentTimeStr);
      }
    }

    // Incrementa pelo intervalo definido
    currentTime += interval;
  }

  // Atualiza os períodos e horários desativados
  store.state.configs.periods = periods;
  selectedTimes.value = unavailableTimes;

  // Atualiza os horários de almoço desativados no estado
  store.state.configs.unavailableTimes = unavailableTimes;
};

const toggleSelection = (time) => {
  const index = selectedTimes.value.indexOf(time);
  if (index === -1) {
    selectedTimes.value.push(time);
  } else {
    selectedTimes.value.splice(index, 1);
  }
};

const selectPeriod = (period) => {
  adjustBusinessHours()
  selectedPeriod.value = period;
};

const isSelected = (time) => {
  return selectedTimes.value?.includes(time);
};

const defaultConfigs = {
  openOnWeekends: false,
  openingHours: "08:00",
  closingTime: "18:00",
  closesForLunch: false,
  hourForLunch: "12:00",
  returnForLunch: "13:00",
  saturdayHour: "13:00",
  serviceInterval: "15",
  periods: {
    Manhã: ["09:00","09:15","09:30","09:45","10:00","10:15","10:30","10:45","11:00","11:15","11:30","11:45"],
    Tarde: ["13:00","13:15","13:30","13:45","14:00","14:15","14:30","14:45","15:00","15:15","15:30","15:45"],
    Noite: ["16:00","16:15","16:30","16:45","17:00","17:15","17:30","17:45","18:00","18:15","18:30","18:45"],
  },
  unavailableTimes: [],
};

onMounted(() => {
  const keys = [
    "openOnWeekends",
    "openingHours",
    "closingTime",
    "closesForLunch",
    "hourForLunch",
    "returnForLunch",
    "saturdayHour",
    "serviceInterval",
    "periods",
    "unavailableTimes",
  ];

  keys.forEach((key) => {
    const getterKey = `get${key}`;
    store.state.configs[key] = store.getters[getterKey] || defaultConfigs[key];
  });

});

watch(
  () => store.state.configs.openingHours,
  () => adjustBusinessHours()
);
watch(
  () => store.state.configs.closingTime,
  () => adjustBusinessHours()
);
watch(
  () => store.state.configs.serviceInterval,
  () => adjustBusinessHours()
);
watch(
  () => store.state.configs.closesForLunch,
  () => adjustBusinessHours()
);

</script>
