import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/collabs/'

class CollabService {
  async getAllColaborators () {
    return http.get(PATH.concat('all'))
  }

  async register (data) {
    return http.post(PATH.concat('new'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async getCollabById (id) {
    return http.get(PATH.concat(id).concat('/collab'))
  }

  async editCollaborator (id, data) {
    return http.post(PATH.concat(id).concat('/edit'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async saveImage (newImg) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company')
      }
    }

    return imgServe.post('new-file', { file: newImg }, headers)
  }

  async editImage (newImg, imgPath) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company'),
        __company_path: imgPath
      }
    }

    return imgServe.post('replace-file', { file: newImg }, headers)
  }

  async deleteCollaborator (id) {
    return http.patch(PATH.concat(id).concat('/disable'))
  }

  async getPermissions () {
    return http.get(PATH.concat('permissions'))
  }
}

export default new CollabService()
