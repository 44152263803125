<template>
  <div class="calendar-container">
    <div class="calendar-header">

      <button @click="prevMonth" class="nav-button ml-2">
        <ChevronDownIcon
          class="h-7 w-7 text-gray-400 rotate-90"
        />
      </button>
      <span class="month-year">{{ monthYear }}</span>
      <button @click="nextMonth" class="nav-button mr-2">
        <ChevronDownIcon
          class="h-7 w-7 text-gray-400 -rotate-90"
        />
      </button>

    </div>
    <div class="calendar-weekdays">
      <span v-for="(day, index) in weekdays" :key="index" class="weekday">{{ day }}</span>
    </div>
    <div class="calendar-days">
      <span
        v-for="(day, index) in daysInMonth"
        :key="index"
        :class="['calendar-day', isToday(day.date) ? 'today' : '', isSelected(day.date) ? 'selected' : '', day.date === null ? 'empty' : '']"
        @click="selectDate(day.date)"
      >
        {{ day.label }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { dataSchedule } from '../store'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import ScheduleService from '../services/schedule.service'
import { useRoute } from 'vue-router'

const store = dataSchedule
const currentDate = ref(new Date())
const selectedDate = ref(null)
const route = useRoute()

const weekdays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']
const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
const daysInMonth = ref([])

const monthYear = computed(() => {
  const month = currentDate.value.getMonth()
  const year = currentDate.value.getFullYear()
  return `${months[month]} ${year}`
})

function updateDaysInMonth () {
  const year = currentDate.value.getFullYear()
  const month = currentDate.value.getMonth()
  const days = []

  const firstDayOfMonth = new Date(year, month, 1).getDay()
  const totalDays = new Date(year, month + 1, 0).getDate()

  for (let i = 0; i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1); i++) {
    days.push({ date: null, label: '' })
  }

  for (let day = 1; day <= totalDays; day++) {
    days.push({
      date: new Date(year, month, day),
      label: day
    })
  }

  daysInMonth.value = days
}

function prevMonth () {
  const month = currentDate.value.getMonth()
  if (month === 0) {
    currentDate.value = new Date(currentDate.value.getFullYear() - 1, 11, 1)
  } else {
    currentDate.value = new Date(currentDate.value.getFullYear(), month - 1, 1)
  }
  updateDaysInMonth()
}

function nextMonth () {
  const month = currentDate.value.getMonth()
  if (month === 11) {
    currentDate.value = new Date(currentDate.value.getFullYear() + 1, 0, 1)
  } else {
    currentDate.value = new Date(currentDate.value.getFullYear(), month + 1, 1)
  }
  updateDaysInMonth()
}

async function selectDate (date) {
  if (date) {
    selectedDate.value = date
    const dateToSave = selectedDate.value.toISOString().split('T')[0]
    store.dispatch('updateClientScheduleDateSelected', dateToSave)

    await ScheduleService.verifyUnavailableTimes(dateToSave, route.query.cId)
      .then(res => {
        store.dispatch('updateUnavailableTimes', res.data)
      })
  }
}

function isToday (date) {
  const today = new Date()
  return date && today.toDateString() === date.toDateString()
}

function isSelected (date) {
  const selected = new Date(selectedDate.value)
  return date && selectedDate.value && selected.toDateString() === date.toDateString()
}

watch(currentDate, updateDaysInMonth, { immediate: true })

onMounted(() => {
  const storedDate = store.getters.getDateSelected

  if (storedDate) {
    const [year, month, day] = storedDate.split('-')
    selectedDate.value = new Date(year, month - 1, day)
  } else {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const day = today.getDate()

    selectedDate.value = new Date(year, month, day)
    store.dispatch('updateClientScheduleDateSelected', selectedDate.value.toISOString().split('T')[0])
  }

  updateDaysInMonth()
})
</script>

<style>
.calendar-container {
  background-color: #21242b;
  padding: 1rem;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: bold;
}

.nav-button {
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 0.5rem;
  color: #fff;
  text-align: center;
}

.weekday {
  font-weight: bold;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
}

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  background-color: #2b3038;
}

.calendar-day.today {
  border: 1px solid #fff;
}

.calendar-day.selected {
  background-color: #fff;
  color: #2b3038;
  font-weight: bold;
}

.calendar-day.empty {
  visibility: hidden;
}
</style>
