<template>
  <div class="loading-container" v-show="this.$store.state.loading" >
    <div class="waterfall">
      <div id="first">
        <svg fill="#fff" height="38px" width="38px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 511.998 511.998" xml:space="preserve">
          <g>
            <g>
              <path d="M334.587,125.373c7.725,0,14.01-6.285,14.01-14.01V80.137c0-7.725-6.285-14.01-14.01-14.01h-5.91
                c-1.455-15.439-7.781-30.042-18.184-41.703c-3.141-3.522-8.544-3.831-12.065-0.688c-3.522,3.141-3.83,8.543-0.688,12.065
                c7.602,8.523,12.364,19.098,13.751,30.326H200.503c3.404-27.597,26.993-49.035,55.496-49.035c4.719,0,8.546-3.826,8.546-8.546
                c0-4.719-3.826-8.546-8.546-8.546c-37.935,0-69.193,29.086-72.672,66.126h-5.916c-7.725,0-14.01,6.285-14.01,14.01v31.225
                c0,7.725,6.285,14.01,14.01,14.01h5.582v261.254h-5.582c-7.725,0-14.01,6.285-14.01,14.01v31.225c0,7.725,6.285,14.01,14.01,14.01
                h5.916c3.48,37.04,34.738,66.126,72.672,66.126c37.935,0,69.193-29.086,72.673-66.126h5.916c7.725,0,14.01-6.285,14.01-14.01
                v-31.225c0-7.725-6.285-14.01-14.01-14.01h-5.582V125.373H334.587z M255.999,494.909c-28.503,0-52.092-21.438-55.495-49.035
                h110.988C308.091,473.47,284.5,494.909,255.999,494.909z M331.507,403.717v25.064h-11.048H191.538H180.49v-25.064h11.048h128.921
                H331.507z M253.76,142.026c-1.667-4.414-6.597-6.643-11.015-4.974l-42.661,16.117v-27.797h111.83v42.957l-111.83,42.25V171.44
                l48.702-18.399C253.202,151.372,255.428,146.441,253.76,142.026z M311.914,186.6v39.139l-111.83,42.25V228.85L311.914,186.6z
                M311.914,244.01v39.139l-111.83,42.25V286.26L311.914,244.01z M311.914,301.42v39.138l-53.389,20.17
                c-4.415,1.668-6.642,6.599-4.974,11.015c1.292,3.421,4.543,5.527,7.996,5.527c1.003,0,2.024-0.178,3.018-0.554l47.348-17.888
                v27.797h-111.83V343.67L311.914,301.42z M320.459,108.282H191.538H180.49V83.218h11.048h128.921h11.048v25.064H320.459z"/>
            </g>
          </g>
        </svg>
      </div>
      <div id="second">
        <svg fill="#fff" height="38px" width="38px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512.001 512.001" xml:space="preserve">
        <g>
          <g>
            <circle cx="300.835" cy="381.397" r="8.501"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M403.227,494.999h-13.141v-5.766c0-12.795-10.409-23.205-23.205-23.205c-17.321,0-31.414-14.093-31.414-31.414V352.51
              c0-4.672-3.769-8.46-8.432-8.497v-38.625h22.628l12.76,37.035c0.955,2.77,3.27,4.854,6.126,5.513l31.936,7.367
              c0.643,0.148,1.285,0.22,1.918,0.22c3.87,0,7.368-2.661,8.275-6.592c1.056-4.574-1.798-9.139-6.372-10.193l-27.345-6.309
              l-9.317-27.04h9.244c18.751,0,34.007-15.256,34.007-34.007s-15.256-34.007-34.007-34.007h-64.666v-30.716h14.333
              c14.375,0,26.069-11.693,26.069-26.069c0-14.375-11.693-26.069-26.069-26.069H172.234l-8.218-54.082
              c-1.714-11.27-10.442-19.76-21.07-21.719l-1.916-12.606c9.565-5.028,15.379-15.73,13.667-26.999l-2.577-16.957
              c-1.046-6.884-4.711-12.949-10.319-17.077c-5.607-4.128-12.485-5.825-19.372-4.78c-14.209,2.163-24.014,15.482-21.855,29.691
              l2.576,16.958c1.713,11.27,10.443,19.767,21.071,21.724l1.915,12.61c-3.46,1.812-6.505,4.377-8.888,7.615
              c-4.129,5.607-5.826,12.487-4.78,19.371l17.792,117.083c1.046,6.884,4.711,12.949,10.319,17.077
              c3.293,2.423,7.025,3.998,10.936,4.682l1.494,3.722c6.489,16.177,21.201,27.099,38.254,28.805
              c3.701,14.772,17.085,25.747,32.987,25.747h50.368v38.625c-4.663,0.037-8.432,3.825-8.432,8.497v17.556h-27.017
              c-2.138,0-4.196,0.805-5.766,2.254l-29.469,27.202c-3.45,3.185-3.664,8.562-0.481,12.012c1.676,1.816,3.958,2.735,6.249,2.735
              c2.061,0,4.13-0.746,5.763-2.254l27.028-24.948h23.693v47.548c0,17.321-14.093,31.414-31.414,31.414
              c-12.795,0-23.205,10.409-23.205,23.205v5.766h-13.141c-4.696,0-8.501,3.805-8.501,8.501c0,4.696,3.805,8.501,8.501,8.501h21.642
              h161.513h21.642c4.696,0,8.501-3.805,8.501-8.501C411.725,498.804,407.921,494.999,403.227,494.999z M326.558,171.523
              c5,0,9.067,4.068,9.067,9.067s-4.068,9.067-9.067,9.067H177.575l-2.756-18.135H326.558z M119.959,44.396l-2.576-16.958
              c-0.751-4.942,2.66-9.575,7.602-10.327c2.393-0.37,4.786,0.227,6.737,1.663s3.225,3.545,3.588,5.94l2.577,16.957
              c0.751,4.943-2.659,9.576-7.602,10.327C125.349,52.74,120.71,49.337,119.959,44.396z M163.338,226.814
              c-1.436,1.951-3.544,3.225-5.939,3.588c-2.396,0.365-4.788-0.228-6.737-1.663c-1.951-1.436-3.225-3.545-3.588-5.94L129.28,105.717
              c-0.364-2.394,0.226-4.788,1.662-6.737c1.437-1.951,3.545-3.225,5.94-3.588c0.461-0.071,0.923-0.105,1.382-0.105
              c1.918,0,3.781,0.609,5.357,1.768c1.951,1.436,3.225,3.545,3.588,5.939l17.792,117.083
              C165.364,222.47,164.775,224.864,163.338,226.814z M191.422,262.53c-2.079-0.321-4.093-0.862-6.017-1.602l-13.43-10.522
              c-1.262-1.81-2.335-3.783-3.184-5.901l-0.114-0.286c3.238-1.794,6.093-4.257,8.354-7.328c4.128-5.607,5.825-12.487,4.779-19.371
              l-1.651-10.863h115.065v30.715h-70.969C208.564,237.373,195.328,248.057,191.422,262.53z M224.255,288.386
              c-9.379,0-17.007-7.628-17.007-17.006c0-9.377,7.628-17.006,17.006-17.006h152.637c9.377,0,17.006,7.628,17.006,17.006
              s-7.628,17.006-17.006,17.006h-58.354h-35.413H224.255z M310.034,305.388v38.621h-18.411v-38.621H310.034z M373.086,494.999
              H228.573v-5.766c0-3.421,2.783-6.203,6.203-6.203c26.697,0,48.415-21.719,48.415-48.415v-73.604h35.276v73.604
              c0,26.697,21.719,48.415,48.415,48.415c3.421,0,6.203,2.783,6.203,6.203V494.999z"/>
          </g>
        </g>
        </svg>
      </div>
      <div id="third">
        <svg fill="#fff" height="38px" width="38px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.001 512.001" xml:space="preserve">
          <g>
            <g>
              <path d="M507.835,62.497l-22.513-13.855c-3.38-2.082-7.74-1.627-10.617,1.11l-12.32,11.71
                c-11.444,10.877-27.239,15.333-42.429,12.298c-0.376-10.301-3.661-20.417-9.625-29.06c-8.804-12.759-22.664-21.243-38.029-23.277
                c-28.054-3.712-54.121,14.345-60.651,42.004c-3.883,16.448-8.336,32.74-13.317,48.859h-3.465c-18.685,0-36.338,7.188-49.71,20.241
                c-10.082,9.841-23.391,15.261-37.48,15.261H26.252c-11.65-23.847-11.635-51.354-0.014-75.145h253.853
                c4.835,0,8.753-3.919,8.753-8.753s-3.918-8.753-8.753-8.753H20.949c-3.164,0-6.081,1.707-7.63,4.465
                c-17.476,31.1-17.77,68.397-0.789,99.77l0.722,1.335c1.531,2.825,4.485,4.585,7.698,4.585h186.731
                c18.685,0,36.338-7.188,49.709-20.241c9.554-9.327,22.01-14.673,35.277-15.208c-33.735,100.347-88.582,193.274-160.851,271.759
                c-14.658,15.919-14.73,39.955-0.169,55.91l18.302,20.054c7.785,8.531,18.876,13.449,30.427,13.493c0.052,0,0.103,0,0.154,0
                c11.447,0,22.456-4.8,30.223-13.184c92.003-99.311,159.538-218.925,196.888-348.084h28.819c35.108,0,66.046-23.665,75.235-57.549
                C512.71,68.498,511.14,64.531,507.835,62.497z M402.329,79.224c-0.015,0.11-0.023,0.219-0.034,0.33
                c-0.167,1.535-0.418,3.072-0.778,4.605C368.076,226.267,297.67,358.298,197.912,465.979c-4.528,4.887-10.696,7.576-17.377,7.575
                c-0.03,0-0.063,0-0.093,0c-6.766-0.026-13.004-2.792-17.563-7.787l-18.302-20.054c-8.394-9.198-8.344-23.065,0.117-32.253
                c90.01-97.752,153.634-217.402,183.996-346.012c4.066-17.229,19.218-29.001,36.355-29.001c1.636,0,3.293,0.107,4.96,0.328
                c10.472,1.386,19.917,7.17,25.918,15.865C400.99,61.986,403.191,70.563,402.329,79.224z M436.461,112.287h-23.969
                c1.867-7.011,3.646-14.05,5.334-21.111c20.333,3.647,41.326-2.488,56.62-17.024l7.438-7.07l10.898,6.707
                C483.828,96.746,461.514,112.287,436.461,112.287z"/>
            </g>
          </g>
          <g>
            <g>
              <circle cx="164.956" cy="429.447" r="9.741"/>
            </g>
          </g>
        </svg>
      </div>
      <div id="fourth">
        <svg fill="#fff" height="38px" width="38px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
          <g>
            <g>
              <circle cx="256.58" cy="448.707" r="10.417"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M264.988,323.921c-0.084-4.787-3.992-8.608-8.759-8.608c-0.053,0-0.106,0-0.159,0.001
                c-4.84,0.085-8.693,4.078-8.607,8.918l0.716,40.509c0.084,4.786,3.992,8.608,8.759,8.608c0.053,0,0.106,0,0.159-0.001
                c4.84-0.085,8.693-4.078,8.607-8.918L264.988,323.921z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M364.67,0c-4.841,0-8.764,3.923-8.764,8.764v33.107h-13.521V8.764c0-4.841-3.923-8.764-8.764-8.764
                s-8.764,3.923-8.764,8.764v33.107h-13.522V8.764c0-4.841-3.923-8.764-8.764-8.764s-8.764,3.923-8.764,8.764v33.107h-13.521V8.764
                c0-4.841-3.923-8.764-8.764-8.764s-8.764,3.923-8.764,8.764v33.107h-13.522V8.764c0-4.841-3.923-8.764-8.764-8.764
                s-8.764,3.923-8.764,8.764v33.107h-13.521V8.764c0-4.841-3.923-8.764-8.764-8.764s-8.764,3.923-8.764,8.764v33.107h-13.521V8.764
                c0-4.841-3.923-8.764-8.764-8.764s-8.764,3.923-8.764,8.764v33.107h-13.521V8.764c0-4.841-3.923-8.764-8.764-8.764
                s-8.764,3.923-8.764,8.764v41.871v95.244c0,25.634,18.683,46.974,43.145,51.153v240.14c0,41.261,33.569,74.829,74.829,74.829
                c41.262,0,74.83-33.569,74.83-74.829V196.837c23.931-4.613,42.066-25.704,42.066-50.96V50.635V8.764
                C373.434,3.923,369.511,0,364.67,0z M355.906,145.879c0,18.958-15.423,34.38-34.381,34.38h-91.234
                c-4.841,0-8.764,3.923-8.764,8.764c0,4.841,3.923,8.764,8.764,8.764h83.548v239.385c0,31.596-25.706,57.301-57.302,57.301
                s-57.301-25.705-57.301-57.301V189.022c0-4.841-3.923-8.764-8.764-8.764c-18.958,0-34.381-15.422-34.381-34.38v-86.48h199.815
                V145.879z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M303.632,79.824h-98.186c-11.132,0-20.191,9.057-20.191,20.189v29.581c0,11.134,9.058,20.191,20.191,20.191h98.186
                c11.132,0,20.191-9.057,20.191-20.191v-29.58C323.822,88.881,314.764,79.824,303.632,79.824z M306.295,129.596
                c0,1.469-1.194,2.663-2.663,2.663h-98.186c-1.469,0-2.663-1.194-2.663-2.663v-29.581c0-1.468,1.194-2.662,2.663-2.662h98.186
                c1.469,0,2.663,1.194,2.663,2.662V129.596z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M256.583,253.773c-19.329,0-35.055,15.726-35.055,35.055v78.29c0,19.329,15.726,35.055,35.055,35.055
                c19.329,0,35.055-15.726,35.055-35.055v-78.29C291.638,269.499,275.913,253.773,256.583,253.773z M274.111,367.118
                c0,9.665-7.863,17.528-17.528,17.528c-9.665,0-17.528-7.863-17.528-17.528v-78.29c0-9.665,7.863-17.528,17.528-17.528
                c9.665,0,17.528,7.863,17.528,17.528V367.118z"/>
            </g>
          </g>
        </svg>
      </div>
      <div id="fifth">
        <svg fill="#fff" height="38px" width="38px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 511.998 511.998" xml:space="preserve">
          <g>
            <g>
              <path d="M384.57,404.636c-19.152-8.077-41.308,0.931-49.387,20.085c-8.079,19.154,0.931,41.309,20.085,49.386
                c4.769,2.011,9.722,2.964,14.601,2.964c14.711,0,28.719-8.664,34.787-23.049C412.732,434.87,403.723,412.715,384.57,404.636z
                M387.165,446.646c-4.011,9.51-15.012,13.981-24.52,9.972c-4.606-1.942-8.18-5.563-10.064-10.194
                c-1.884-4.632-1.851-9.719,0.091-14.325c3.013-7.142,9.967-11.443,17.272-11.443c2.422,0,4.882,0.473,7.25,1.472
                C386.702,426.136,391.175,437.137,387.165,446.646z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M437.191,412.014c-7.308-17.969-21.176-32.018-39.05-39.556c-22.477-9.479-47.089-6.726-66.42,5.202l-61.744-150.744
                c-1.907-4.654-7.113-7.012-11.866-5.378c-4.573,1.573-9.597,1.086-13.783-1.332c-4.187-2.417-7.118-6.526-8.044-11.271
                l-1.208-6.197c-0.003-0.015-0.006-0.03-0.009-0.047L200.365,24.741l46.844,111.452c0.001,0.005,0.004,0.009,0.006,0.014
                l30.274,72.029c0.005,0.013,0.01,0.025,0.015,0.038L331.309,337.4c2.031,4.831,7.593,7.103,12.427,5.072
                c4.831-2.031,7.103-7.594,5.072-12.427l-52.703-126.507l35.344-181.242c2.087-9.417-3.036-18.377-12.239-21.353
                c-9.267-2.998-18.728,1.344-22.502,10.321l-40.709,96.854l-40.709-96.854c-3.772-8.976-13.235-13.321-22.501-10.321
                c-9.205,2.976-14.327,11.938-12.239,21.355l35.342,181.239l-68.669,163.377c-11.271-0.812-22.654,1.022-33.367,5.541
                c-17.874,7.539-31.741,21.587-39.049,39.556c-7.308,17.969-7.18,37.709,0.358,55.582c6.754,16.014,19.036,29.085,34.583,36.806
                c10.179,5.057,21.249,7.598,32.336,7.596c9.581,0,19.178-1.898,28.22-5.712c17.873-7.539,31.741-21.588,39.049-39.557
                s7.18-37.709-0.358-55.582c-3.289-7.796-7.817-14.828-13.367-20.889l49.384-120.567c1.987-4.85-0.335-10.393-5.186-12.38
                c-4.852-1.987-10.393,0.337-12.38,5.186l-47.153,115.122c-3.431-2.113-7.049-3.972-10.855-5.52
                c-1.087-0.442-2.182-0.854-3.281-1.243l59.574-141.738c2.57,2.962,5.633,5.519,9.106,7.523c6.28,3.627,13.441,5.221,20.532,4.682
                l61.021,148.978c-5.484,5.972-10.058,12.957-13.386,20.844c-15.562,36.896,1.794,79.576,38.692,95.139
                c9.044,3.814,18.639,5.714,28.22,5.714c11.086,0,22.156-2.542,32.336-7.598c15.547-7.723,27.828-20.794,34.583-36.806
                C444.373,449.723,444.499,429.983,437.191,412.014z M311.626,24.774l-28.732,147.335l-16.601-39.497L311.626,24.774z
                M177.729,399.258c0.161,0.161,0.333,0.311,0.506,0.462c5.668,5.162,10.202,11.531,13.269,18.806
                c5.569,13.202,5.663,27.783,0.264,41.055c-5.397,13.273-15.641,23.648-28.842,29.218c-14.409,6.079-30.713,5.57-44.737-1.396
                c-11.479-5.701-20.547-15.355-25.536-27.183c-5.569-13.202-5.663-27.783-0.264-41.055c5.397-13.272,15.641-23.648,28.842-29.216
                c6.711-2.831,13.773-4.246,20.843-4.246c6.84,0,13.686,1.326,20.213,3.981C168.03,392.018,173.227,395.262,177.729,399.258z
                M419.344,460.219c-4.988,11.828-14.058,21.482-25.536,27.183c-14.022,6.965-30.327,7.472-44.736,1.396
                c-27.252-11.495-40.073-43.02-28.577-70.272c8.633-20.468,28.563-32.795,49.497-32.795c6.94,0,13.991,1.355,20.775,4.218
                c13.202,5.568,23.445,15.945,28.843,29.216C425.007,432.437,424.913,447.017,419.344,460.219z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M176.816,424.721c-8.078-19.154-30.234-28.162-49.386-20.085c-9.279,3.914-16.477,11.205-20.271,20.534
                c-3.794,9.328-3.727,19.575,0.186,28.853c3.914,9.279,11.205,16.477,20.534,20.271c4.587,1.867,9.397,2.798,14.205,2.798
                c4.968,0,9.934-0.996,14.647-2.984c9.278-3.914,16.477-11.205,20.271-20.534C180.795,444.246,180.728,434,176.816,424.721z
                M159.418,446.422c-1.883,4.631-5.458,8.252-10.065,10.196c-4.606,1.942-9.693,1.977-14.325,0.092
                c-4.631-1.883-8.252-5.458-10.194-10.064s-1.975-9.693-0.092-14.325c1.883-4.631,5.458-8.252,10.065-10.196
                c2.368-0.998,4.826-1.472,7.248-1.472c7.303,0,14.258,4.302,17.271,11.443C161.268,436.704,161.301,441.791,159.418,446.422z"/>
            </g>
          </g>
          <g>
            <g>
              <circle cx="256.971" cy="197.686" r="9.491"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingVue'
}
</script>

<style>
@-webkit-keyframes waterfall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-250%);
            transform: translateY(-250%); }
  40%,
  60% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(250%);
            transform: translateY(250%); }
}
@keyframes waterfall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-250%);
            transform: translateY(-250%); }
  40%,
  60% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(250%);
            transform: translateY(250%); }
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.566);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.waterfall div {
  -webkit-animation: waterfall 1.5s infinite;
    animation: waterfall 1.5s infinite;
  height: 20px;
  color: rgba(255, 255, 255, 0.802);
  left: 50%;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 20px;
  font-size: 25px;
}
  .waterfall #fifth {
    -webkit-animation-delay: 1.25s;
            animation-delay: 1.25s;
    margin-left: -90px;
  }
  .waterfall #first {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
    margin-left: -50px;
  }
  .waterfall #fourth {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    margin-left: -15px;
  }
  .waterfall #second {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    margin-left: 25px;
  }
  .waterfall #third {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
    margin-left: 60px;
  }
</style>
