<template>
  <div>
    <div class="fadeIn first mb-5">
      <img
        :src="appImage"
        alt="BarbApp"
        class="w-32 h-32 mx-auto rounded-full"
      />
    </div>
    <div id="form-fields" class="lg:col-span-2">
      <input
        class="shadow-sm fadeIn second"
        type="email"
        placeholder="Email de acesso"
        v-model="store.email"
        autocomplete="on"
        name="email"
      />
      <br>
      <span name="email" class="text-red-600 text-sm"></span>

      <div class="p-2 flex justify-center">
        <CreateButton
          class="mt-5"
          @click="sendMailConfirmation()"
          :label="'Enviar código'"
          :icon="'fa-solid fa-receipt'"
          :size="true"
        />
      </div>

    </div>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import AuthService from '../services/auth.service'
import SetCode from './SetCode.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { useToast } from 'vue-toastification'
import { dataAuth } from '../store'
import { storeApp } from '@/store'
import { markRaw } from 'vue'
import * as yup from '@/validator'

const toast = useToast()
const store = dataAuth.state.forgotPassword
const appImage = require('@/assets/logo/app.png')

const form = yup.object().shape({
  email: yup.string().required('Email necessário').label('Email').min(5).max(150)
})

async function sendMailConfirmation () {
  storeApp.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    storeApp.state.loading = false
    return
  }

  await AuthService.forgotPassword(store)
    .then(res => {
      storeApp.state.loading = false
      if (res.data.success) {
        dataAuth.dispatch('updateComponentActive', markRaw(SetCode))
      }
    })
    .catch(err => {
      storeApp.state.loading = false
      if (err.response.status && err.response.status === 401) {
        return toast(err.response.data.message, {
          toastClassName: 'toast-warning'
        })
      }

      return toast('Algo deu errado ao verificar e-mail', {
        toastClassName: 'toast-error'
      })
    })
}

</script>

<style lang="scss" scoped>
input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #39ace7;
  }
  &:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
}
input[type="text"],
input[type="password"],
input[type="email"] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  &:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.357);
  }
}
</style>
