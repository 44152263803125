<template>
  <div class="input-container relative">
    <!-- Campo de entrada -->
    <input
      v-model="internalValue"
      :type="type"
      :placeholder="isFocused || internalValue ? '' : props.requiredField ? props.label + ' *' : props.label"
      :name="name"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :min="min"
      class="input-field"
      :style="{
        'border-bottom': internalValue ? '2px solid #007bff' : '',
        'text-align': props.center ? 'center' : ''
      }"
    />

    <!-- Rótulo flutuante -->
    <label 
      v-if="label" 
      for="input-field" 
      class="input-label w-full"
      :class="{ 
        'active': props.activeDefault || isFocused || internalValue,
      }"
    >
      <label v-if="props.activeDefault || isFocused || internalValue">
        {{ props.info }} <a v-if="requiredField" class="text-red-600 ml-1">*</a>
      </label>
    </label>
    
    <label class="input-highlight"></label>

    <!-- clear button -->
    <slot name="append" />

  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  type: {
    type: String,
    default: "text",
  },
  name: {
    type: String,
    default: "",
  },
  info: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  requiredField: {
    type: Boolean,
    default: false
  },
  center: {
    type: Boolean,
    default: false
  },
  activeDefault: {
    type: Boolean,
    default: false
  },
  min: {
    type: Number,
    default: null, // Valor mínimo para campos numéricos
  },
});

const emit = defineEmits(["update:modelValue", "blur"]);
const internalValue = ref(props.modelValue);
const isFocused = ref(false);

watch(
  () => props.modelValue,
  (newVal) => {
    internalValue.value = newVal;
  }
);

function onInput(event) {
  const inputValue = event.target.value;
  internalValue.value = inputValue;
  emit("update:modelValue", inputValue);
}

function onBlur() {
  isFocused.value = false;
  emit("blur", internalValue.value);
}

function onFocus() {
  isFocused.value = true;
}
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-bottom: 0px;
}

.input-field {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  background-color: transparent;
  transition: border-color 0.3s ease-in-out;
}

.input-field:focus {
  border-bottom: 2px solid #007bff;
}

/* Estilo do rótulo */
.input-label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #ccc;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  text-align: left;
}

/* Quando o input está focado ou possui conteúdo */
.input-label.active {
  top: -10px;
  font-size: 12px;
  color: #2b3038;
}

/* Destaque animado */
.input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #007bff;
  transition: width 0.3s ease-in-out;
}

.input-field:focus + .input-label + .input-highlight {
  width: 100%;
}
</style>
