<template>
  <div class="rounded-lg w-full max-w-md bg-[#21242b]">
    <div
      class="text-white text-left p-4 rounded-tr-md rounded-tl-md bg-[#2b3038]"
    >
      <h2 class="text-xl font-semibold mt-auto mb-auto flex">
        <UserCircleIcon class="h-9 w-9 mr-3" />
        <span class="mt-auto mb-auto">
          Selecione o Barbeiro
        </span>
      </h2>
    </div>

    <div class="p-4 max-h-[65vh] overflow-y-auto">
      <ul class="space-y-4">
        <div
          v-for="barbeiro in barbeiros"
        >
          <li
            v-if="barbeiro.available"
            :key="barbeiro.id"
            :class="[
              'flex items-center p-4 rounded-lg cursor-pointer transition',
              selectedBarberId === barbeiro.id ? 'bg-[#1a1d23] border border-[#6fb171]' : 'bg-[#2b3038] hover:bg-[#343a41]'
            ]"
            @click="selectBarber(barbeiro)"
          >
            <div class="w-16 h-16 rounded-full overflow-hidden mr-2">
              <img
                :src="barbeiro.image ? 
                  imgUrl + barbeiro.image 
                  : defaultBarberImage"
                alt="Foto do barbeiro"
                class="w-full h-full object-cover"
              />
            </div>
  
            <!-- Informações do Barbeiro -->
            <div class="flex-1">
              <!-- Nome do Barbeiro -->
              <p class="text-white font-semibold text-lg pb-1">
                {{ barbeiro.name }}
              </p>
  
              <div class="flex text-sm text-gray-400 pt-1 w-full justify-center">
                <!-- Nome Profissional -->
                <div class="mr-6">
                  <span class="font-medium">Profissional:</span><br>
                  <span class="text-white">{{ getFirstName(barbeiro.name) }}</span>
                </div>
                <!-- Serviços -->
                <div>
                  <span class="font-medium">Serviços:</span><br>
                  <span class="text-white">{{ barbeiro.services }}</span>
                </div>
              </div>
            </div>
          </li>
          
          <!-- else -->
          <li
            v-else
            :class="[
              'flex items-center p-4 rounded-lg cursor-pointer transition',
              'bg-[#1a1d23] border border-gray-600'
            ]"
          >
            <!-- foto do barbeiro -->
            <div class="w-16 h-16 rounded-full overflow-hidden mr-2">
              <img
                :src="barbeiro.image ? 
                  imgUrl + barbeiro.image 
                  : defaultBarberImage"
                alt="Foto do barbeiro"
                class="w-full h-full object-cover"
              />
            </div>
  
            <!-- Informações do Barbeiro -->
            <div class="flex-1">
              <!-- Nome do Barbeiro -->
              <p class="text-gray-400 font-semibold text-lg pb-1">
                {{ barbeiro.name }}
              </p>
  
              <div class="flex text-sm text-gray-600 pt-1 w-full justify-center">
                <p>
                  indisponivel no momento
                </p>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { UserCircleIcon } from '@heroicons/vue/outline'
import { onMounted, ref, computed } from 'vue'
import { dataSchedule } from '../store'

const imgUrl = ref(process.env.VUE_APP_IMAGE_SERVE.concat("storage/"));
const defaultBarberImage = require('@/assets/default_profile.webp')
const informations = dataSchedule.getters
const selectedBarberId = ref(null)
const store = dataSchedule

const barbeiros = computed(() => informations.getBarbers)

function selectBarber (barber) {
  selectedBarberId.value = barber.id
  store.dispatch('updateClientScheduleBarberSelected', { id: barber.id, name: barber.name, image: barber.image, phone: barber.phone })
  store.commit('disableModal')
}

function getFirstName (fullName) {
  return fullName.split(' ')[0]
}

onMounted(() => {
  if (store.getters.getBarberSelected) { selectedBarberId.value = store.getters.getBarberSelected.id }
})

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.modal-content {
  position: relative;
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 24rem;
}
</style>
