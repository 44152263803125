<template>
  {{ totalAmount }}
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in paginatedItems" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.name.length > 20
                  ? row.name.slice(0, 20).concat("...")
                  : row.name
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.product.length > 20
                  ? row.product.slice(0, 20).concat("...")
                  : row.product
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.client.length > 20
                  ? row.client.slice(0, 20).concat("...")
                  : row.client
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2 text-[#6fb171] font-semibold">
                {{ parsePrice(row.total) }}
              </span>
            </div>
          </td>

          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.method }}
              </span>
            </div>
          </td>

          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.create }}
              </span>
            </div>
          </td>

          <td class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButton
              :edit="false" :report="false"
              @delete="deleteSale(paginatedItems[index].id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="this.filteredItems.length > 10">
    <section class="mt-4 flex justify-center">
      <Buttons
        class="mr-4"
        @click="prevPage"
        :disabled="currentPage === 1"
        :label="'Anterior'"
        :icon="'fa-solid fa-arrow-left'"
      />

      <Buttons
        class="ml-4"
        @click="nextPage"
        :disabled="currentPage === totalPages"
        :label="'Próximo'"
        :icon="'fa-solid fa-arrow-right'"
      />
    </section>

    <p>Página {{ currentPage }} de {{ totalPages }}</p>
  </div>
</template>

<script>
import ActionsButton from '@/components/Tables/ActionsButton.vue'
import MovimentService from '../services/moviment.service'
import Buttons from '@/components/Buttons/Create.vue'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import { dataMoviment } from '../store'
import { storeApp } from '@/store'

export default {
  props: {
    center: { Boolean, default: false },
    items: { type: Array, required: true },
    itemsPerPage: { type: Number, default: 10 }
  },
  components: {
    Buttons,
    ActionsButton
  },
  data () {
    return {
      currentPage: 1,
      toast: useToast(),
      storeApp: storeApp,
      store: dataMoviment
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredItems () {
      return this.items.filter(item => {
        const { name, product, client, method } = this.store.state.filters.fields
        const dateRange = this.store.state.filters.date

        const nameMatch = name
          ? item.name.toLowerCase().includes(name.toLowerCase())
          : true

        const productMatch = product
          ? item.product.toLowerCase().includes(product.toLowerCase())
          : true

        const clientMatch = client
          ? item.client.toLowerCase().includes(client.toLowerCase())
          : true

        const methodMatch = method
          ? item.method.toLowerCase().includes(method.toLowerCase())
          : true

        let dateMatch = true
        if (dateRange?.length > 0) {
          let [startDate, endDate] = dateRange.map(date => moment(date, 'DD/MM/YY'))
          const itemDate = moment(item.create, 'DD/MM/YY')

          if (!endDate.isValid()) endDate = startDate.clone()

          dateMatch = itemDate.isBetween(startDate, endDate, 'day', '[]')
        }

        return nameMatch && productMatch && clientMatch && dateMatch && methodMatch
      })
    },
    paginatedItems () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.filteredItems.slice(start, end)
    },
    totalAmount () {
      return this.calculateTotal(this.filteredItems)
    }
  },
  methods: {
    parsePrice (value) {
      // Verifica se o valor é um número válido
      if (typeof value !== 'number') {
        value = parseFloat(value)
      }

      // Formata o número para o formato de moeda brasileiro
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    calculateTotal (items) {
      const total = items.reduce((total, item) => {
        return total + item.total
      }, 0).toFixed(2)

      this.store.dispatch('updateTotalAmount', total)
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    deleteSale (id) {
      const permission = localStorage.getItem('__permissions')

      if (!permission.split(',').includes('delete_haircuts')) {
        return this.toast('Você não tem permissão para remover movimentações', {
          toastClassName: 'toast-info'
        })
      }
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente remover essa venda ?')
      if (confirm) {
        MovimentService.deleteSale(id).then(res => {
          if (res.data.success) {
            this.toast(res.data.success, {
              toastClassName: 'toast-success'
            })
          }

          this.$emit('reload')
        }).catch(err => {
          this.toast(err.response.data.error, { toastClassName: 'toast-error' })
        })
      }
    }
  }
}
</script>

<style scoped>
button {
  margin: 5px;
}
</style>
