<template>
  <div>
    <PageTitleVue> Atualizar dados </PageTitleVue>

    <ContainerVue>
      <Form id="form-fields" :descriptionTitle="'Dados pessoais'">
        <Password 
          :create="false" 
          :descriptionTitle="'Senha de acesso'"
          :descriptionSubTitle="'Altere a senha de acesso de seu colaborador'"
        />
        <ShiftForm 
          :descriptionTitle="'Horarios de atendimento'" 
          :descriptionSubTitle="'Informe os turnos que esse colaborador estará disponivel para serviços'"
        />
        <PermissionsForm :create="false" />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="updateCollaborator"
            :icon="'fa-solid fa-user-plus'"
            :label="'Atualizar'"
          />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import CollabService from '../services/collab.service'
import ContainerVue from '@/components/Container.vue'
import PermissionsForm from './PermissionsForm.vue'
import Form from '../layouts/Form.vue'
import Password from './PasswordForm.vue'
import ShiftForm from './ShiftForm.vue'
import * as yup from '@/validator'
import { dataCollab } from '../store'
import { storeApp } from '@/store'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onBeforeMount, onMounted } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

const collab_id = useRoute().params.id
const appStore = storeApp
const store = dataCollab
const toast = useToast()

const form = yup.object().shape({
  name: yup.string().required().label('Nome').min(3).max(100),
  phone: yup.string().nullable().label('Celular').max(20),
  email: yup.string().required().label('Email').max(100),
  role: yup.string().nullable().label('Função').max(50)
})

const password = yup.object().shape({
  password: yup.object().shape({
    old: yup.string().min(5, 'Inválido').required('Por favor informe a senha anterior'),
    newPass: yup.string().required('É necessário informar a nova senha').min(5, 'Deve ter no mínimo 5 caracteres'),
    confirm: yup.string().oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais').required('Por favor confirme a nova senha')
  })
})

onBeforeMount(async () => {
  store.commit('reset')
  appStore.state.loading = true
  await getCollab()
  await permissions()
  appStore.state.loading = false
})

async function getCollab () {
  store.commit('reset')
  await CollabService.getCollabById(collab_id)
    .then(res => {
      store.commit('updateCollabFields', res.data)
      store.state.userPermissions = res.data.permissions
      store.dispatch('setCollabSettings', res.data.settings)
    }).catch(err => {
      toast('Algo deu arrado ao buscar dados do colaborador', {
        toastClassName: 'toast-error'
      })
    })
}

// lista todas as permissões
async function permissions () {
  await CollabService.getPermissions()
    .then(res => {
      store.state.permissions = res.data
    }).catch(err => {
      toast('Algo deu arrado ao buscar dados do funcionário', {
        toastClassName: 'toast-error'
      })
    })
}

async function updateCollaborator () {
  // validação dos campos de login
  const validatePassword = undefined
  if (store.state.password.newPass) { validatePassword = (await validateFormField(password, store.state)) }

  if (validatePassword && validatePassword.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  const data = Object.assign({}, store.state)

  delete data.password.deleted_at
  delete data.userPermissions
  delete data.permissions
  delete data.haircuts
  delete data.password.confirm
  delete data.password.old
  delete data.deleted_at
  delete data.indicated_by
  delete data.settings

  appStore.state.loading = true

  const validate = (
    await validateFormField(form, store.state)
  )

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  if ( // se caso exista uma foto, remova e troque pela nova
    store.state.image &&
    typeof store.state.image === 'object' &&
    typeof store.state.imgPath === 'string' &&
    store.state.imgPath !== '[]'
  ) {
    await CollabService.editImage(
      store.state.image,
      store.state.imgPath
    )
      .then((res) => {
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  }
  else if ( // adicione uma nova foto
    store.state.image &&
    typeof store.state.image !== 'string'
  ) {
    await CollabService.saveImage(store.state.image)
      .then((res) => {
        // store.state.imgPath = res.data
        // store.state.image = res.data
        console.log(res);
        
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  }
  else { // passa o caminho da imagem
    // store.state.image = store.state.imgPath
    data.image = store.state.imgPath
  }

  delete data.imgPath

  await CollabService.editCollaborator(store.state.id, data)
    .then(res => {
      appStore.state.loading = false
      if (res.data.success) {
        toast(res.data.success, {
          toastClassName: 'toast-success'
        })
      }
    })
    .catch(err => {
      appStore.state.loading = false
      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    })
}

</script>

<style>

</style>
