<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
        
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.state.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.state.imgPath"
                  alt="Logo"
                  class="mt-8 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <!-- Nome de usuário -->
                <div class="md:col-span-3">
                  <BaseInput
                    name="name"
                    :info="'Seu nome de usuário'"
                    v-model="store.state.name"
                    label="Nome de usuário"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.name = null"
                      />
                    </template>
                  </BaseInput>
                  <span name="name" class="text-red-600 text-sm" />
                </div>

                <!-- Função -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="user.function"
                    v-model="store.state.role"
                    :info="'Seus serviços'"
                    label="Função"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.role = null"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- Email -->
                <div class="md:col-span-3">
                  <BaseInput
                    type="email"
                    name="user.email"
                    v-model="store.state.email"
                    :info="'Seu email de acesso'"
                    label="Email"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.email = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="email" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="phone"
                    v-model="store.state.phone"
                    v-mask="'(##) # ####-####'"
                    :info="'Seu telefone para contato'"
                    label="Celular"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.phone = null"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- imagem -->
                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="Logo"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <!-- imagem -->
                <div class="md:col-span-5 mt-4 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[10rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <!-- <i class="fa-solid fa-building text-blueGray-600"></i> -->
                    <i class="fa-regular fa-circle-user text-blueGray-600"></i>
                    Foto de perfil
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="p-2 mt-1 mb-1" />

        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from './../component/CleanInputButton.vue'
import { useToast } from 'vue-toastification'
import { dataUser } from '../store/'
import BaseInput from '@/components/Inputs/BaseInput.vue';

export default {
  components: {
    CleanInputButton,
    BaseInput
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataUser
    const toast = useToast()
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

    return {
      store,
      toast,
      fileUrl,
      selectedFileName: undefined,
      selectedFileUrl: undefined,
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.state.image = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.state.image = undefined
          return this.toast('Tipo de arquivo não é válido !', {
            toastClassName: 'toast-error'
          })
        }
      }
    }
  }
}
</script>
