<template>
  <div class="border w-full max-w-[100%] border-gray-300 p-1 mt-1 rounded-md">
    <div class="w-full">
      <div class="flex p-1 items-center">
        <DatePicker
          v-model="store.filters.date"
          :locale="'pt'"
          :format="dateFormat"
          range
        />

        <!-- Botão para ativar o dropdown -->
        <div class="relative ml-1">
          <button @click="toggleDropdown" class="p-2 rounded-full ml-1 hover:shadow-lg hover:-translate-y-0.5 hover:-translate-x-0.5 transition-all">
            <svg width="25" height="25" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.62248 0.833496V3.50016M4.28914 0.833496V3.50016M0.955811 6.16683H12.9558M2.28914 2.16683H11.6225C12.3589 2.16683 12.9558 2.76378 12.9558 3.50016V12.8335C12.9558 13.5699 12.3589 14.1668 11.6225 14.1668H2.28914C1.55276 14.1668 0.955811 13.5699 0.955811 12.8335V3.50016C0.955811 2.76378 1.55276 2.16683 2.28914 2.16683Z" stroke="#111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>

          <!-- Dropdown com as opções de filtros -->
          <div v-if="isDropdownOpen" class="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg p-2 z-10">
            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setToday"
            >
              Hoje
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setYesterday"
            >
              Ontem
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setLast7Days"
            >
              Últimos 7 Dias
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setThisMonth"
            >
              Este Mês
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setLastMonth"
            >
              Mês Anterior
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded border-b"
              @click="setThisYear"
            >
              Este Ano
            </button>

            <button
              class="block w-full text-left p-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all rounded"
              @click="setAllTime"
            >
              Todo Período
            </button>
          </div>

        </div>

        <FilterFields />
      </div>
    </div>
  </div>
</template>

<script setup>
import '@vuepic/vue-datepicker/dist/main.css'
import FilterFields from './FilterFields.vue'
import DatePicker from '@vuepic/vue-datepicker'
import { subDays, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'
import { dataCosts } from './../../store'
import { ref } from 'vue'

const store = dataCosts.state

const dateFormat = 'dd/MM/yy'
const isDropdownOpen = ref(false)

function toggleDropdown () {
  isDropdownOpen.value = !isDropdownOpen.value
}

const setToday = () => {
  store.filters.date = [new Date(), new Date()]
  toggleDropdown()
}

const setYesterday = () => {
  const yesterday = subDays(new Date(), 1)
  store.filters.date = [yesterday, yesterday]
  toggleDropdown()
}

const setLast7Days = () => {
  store.filters.date = [subDays(new Date(), 7), new Date()]
  toggleDropdown()
}

const setThisMonth = () => {
  const start = startOfMonth(new Date())
  const end = endOfMonth(new Date())
  store.filters.date = [start, end]
  toggleDropdown()
}

const setLastMonth = () => {
  const start = startOfMonth(subDays(new Date(), 30))
  const end = endOfMonth(subDays(new Date(), 30))
  store.filters.date = [start, end]
  toggleDropdown()
}

const setThisYear = () => {
  const start = startOfYear(new Date())
  const end = endOfYear(new Date())
  store.filters.date = [start, end]
  toggleDropdown()
}

const setAllTime = () => {
  const start = new Date('2024-08-17')
  const end = new Date()
  store.filters.date = [start, end]
  toggleDropdown()
}
</script>
