import { createStore } from 'vuex'

export const dataSchedule = createStore({
  state: () => {
    return {
      modalSchedulesIsOpen: false,
      modalIsOpen: false,
      company_logo: '',
      hasSchedules: false,
      client_schedule: {
        name: '',
        descriptionSubTitlePage: '',
        barbers: [],
        serviceList: [],
        openingHours: [],
        unavailableTimes: [],
        services: {
          barber: '',
          services: [],
          hour: '',
          date: ''
        }
      },
      schedules: [
        {
          data: '',
          diaSemana: '',
          agendamentos: '',
          collect: [
            {
              horario: '00:00',
              cliente: '',
              servico: '',
              preco: 0,
              duracao: 0,
              barber_id: '',
              barber: ''
            }
          ],
          selecionado: false
        }
      ]
    }
  },
  getters: {
    getBarberSelected: state => state.client_schedule.services.barber,
    getServicesSelected: state => state.client_schedule.services.services,
    getHourSelected: state => state.client_schedule.services.hour,
    getDateSelected: state => state.client_schedule.services.date,
    getCompanyLogo: state => state.company_logo,
    getModalState: state => state.modalIsOpen,
    getModalSchedulesState: state => state.modalSchedulesIsOpen,
    hasSchedules: state => state.hasSchedules,
    checkSchedulingValues: (state) => {
      if (
        state.client_schedule.services.barber &&
        state.client_schedule.services.services.length > 0 &&
        state.client_schedule.services.hour
      ) {
        return true
      }
      return false
    },
    getSubTitlePage: state => state.client_schedule.descriptionSubTitlePage,
    getBarbers: state => state.client_schedule.barbers,
    getServiceList: state => state.client_schedule.serviceList,
    getOpeningHours: state => state.client_schedule.openingHours,
    getUnavailableTimes: state => state.client_schedule.unavailableTimes,
    getSchedules: state => state.schedules,
    getModalSchedules: state => state.modalSchedulesIsOpen,
  },
  mutations: {
    setHasSchedules (state, val) {
      state.hasSchedules = val
    },
    setBarberSelected (state, value) {
      state.client_schedule.services.barber = value
    },
    setServicesSelected (state, value) {
      state.client_schedule.services.services = value
    },
    setHourSelected (state, value) {
      state.client_schedule.services.hour = value
    },
    setDateSelected (state, value) {
      state.client_schedule.services.date = value
    },
    resetClientFields (state) {
      state.client_schedule.name = ''
      state.client_schedule.services.barber = ''
      state.client_schedule.services.hour = ''
      state.client_schedule.services.date = ''
      state.client_schedule.services.services = []
    },
    updateSubTItle (state, value) {
      state.client_schedule.descriptionSubTitlePage = value
    },
    activateModal (state) {
      state.modalIsOpen = true
    },
    disableModal (state) {
      state.modalIsOpen = false
    },
    activateModalSchedules (state) {
      state.modalSchedulesIsOpen = true
    },
    disableModalSchedules (state) {
      state.modalSchedulesIsOpen = false
    },
    defineDataCompany (state, value) {
      state.client_schedule.barbers = value.barbers
      state.client_schedule.serviceList = value.services
      state.client_schedule.openingHours = JSON.parse(value.times.value)
      state.client_schedule.unavailableTimes = value.unavailableTimes

      state.company_logo = value.company
    },
    checkUnavailableTimes (state, value) {
      state.client_schedule.unavailableTimes = value
    },
    clearSchedules (state, val) {
      state.schedules = []
    },
    setSchedules (state, val) {
      const monthMap = {
        janeiro: 1,
        fevereiro: 2,
        março: 3,
        abril: 4,
        maio: 5,
        junho: 6,
        julho: 7,
        agosto: 8,
        setembro: 9,
        outubro: 10,
        novembro: 11,
        dezembro: 12
      }

      val.forEach(newDay => {
        // Verificar se o novo dia tem uma data válida
        if (!newDay.data || !newDay.diaSemana || !newDay.mes || !Array.isArray(newDay.collect)) {
          console.warn('Novo dia inválido encontrado e ignorado:', newDay)
          return // Ignora dias inválidos
        }

        // Encontrar o dia correspondente no estado atual pelo campo "data" e "mes"
        const existingDayIndex = state.schedules.findIndex(day => day.data === newDay.data && day.mes === newDay.mes)

        if (existingDayIndex !== -1) {
          // Se o dia já existe, atualiza os agendamentos (collect) dentro dele
          const existingDay = state.schedules[existingDayIndex]

          // Atualizar agendamentos existentes ou adicionar novos
          newDay.collect.forEach(newAppointment => {
            const existingAppointmentIndex = existingDay.collect.findIndex(
              appointment => appointment.id === newAppointment.id
            )

            if (existingAppointmentIndex !== -1) {
              // Atualiza o agendamento existente
              existingDay.collect[existingAppointmentIndex] = {
                ...existingDay.collect[existingAppointmentIndex],
                ...newAppointment
              }
            } else {
              // Adiciona novo agendamento
              existingDay.collect.push(newAppointment)
            }
          })

          // Preservar outras propriedades do dia (como `selecionado`)
          state.schedules[existingDayIndex] = {
            ...existingDay,
            agendamentos: existingDay.collect.length, // Recalcula a quantidade de agendamentos
            collect: existingDay.collect // Garantir que não sobrescrevemos agendamentos já existentes
          }
        } else {
          // Se o dia não existe, adiciona ele ao estado
          state.schedules.push({
            ...newDay,
            agendamentos: newDay.collect.length, // Definir o número inicial de agendamentos
            selecionado: false // Adiciona com `selecionado` como `false` por padrão
          })
        }
      })

      // Remover qualquer entrada vazia existente no estado (defensivo)
      state.schedules = state.schedules.filter(day => day.data && day.diaSemana && day.mes)

      // Função para ordenar considerando o mês e o dia
      state.schedules.sort((a, b) => {
        const monthA = monthMap[a.mes.toLowerCase()] || 0
        const monthB = monthMap[b.mes.toLowerCase()] || 0

        if (monthA !== monthB) {
          return monthA - monthB
        } else {
          // Se os meses forem iguais, ordenar pelo dia
          return parseInt(a.data, 10) - parseInt(b.data, 10)
        }
      })
    }
  },
  actions: {
    updateClientScheduleBarberSelected ({ commit }, val) {
      commit('setBarberSelected', val)
    },
    updateClientScheduleServicesSelected ({ commit }, val) {
      commit('setServicesSelected', val)
    },
    updateClientScheduleHourSelected ({ commit }, val) {
      commit('setHourSelected', val)
    },
    resetClientScheduleFields ({ commit }) {
      commit('resetClientFields')
    },
    updateDescriptionPage ({ commit }, val) {
      commit('updateSubTItle', val)
    },
    updateClientScheduleDateSelected ({ commit }, val) {
      commit('setDateSelected', val)
    },
    setCompanyValues ({ commit }, data) {
      commit('defineDataCompany', data)
    },
    updateUnavailableTimes ({ commit }, data) {
      commit('checkUnavailableTimes', data)
    },
    updateSchedulesFields ({ commit }, data) {
      commit('setSchedules', data)
    },
    disableModalSchedules ({ commit }) {
      commit('disableModalSchedules')
    },

  }
})
