<template>
  <section>
    <LoadingVue />
    <div v-if="this.showMenu == false">
      <RouterView class="router-view" v-slot="{ Component }">
        <Transition mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </div>
    <!-- <TheMenu v-if="storeApp.state.showMenu == true"> -->
    <TheMenu v-if="this.showMenu">
      <RouterView class="router-view" v-slot="{ Component }">
        <Transition mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </TheMenu>
  </section>
</template>

<script>
import TheMenu from './components/Navigation/Desktop.vue'
import LoadingVue from '@/components/Loading.vue'
import router from './router'
import { RouterView } from 'vue-router'
import { storeApp } from './store/'
import { mapGetters } from 'vuex'

export default {
  name: 'Application',
  components: {
    RouterView,
    LoadingVue,
    TheMenu
  },
  data () {
    const accessToken = storeApp.state.accessToken

    return {
      router,
      storeApp,
      accessToken,
      showMenu: true
    }
  },
  computed: {
    ...mapGetters(['getMenuState'])
  },
  watch: {
    getMenuState (val) {
      if (val) {
        this.showMenu = true
      } else { this.showMenu = false }
    }
  },
  mounted () {
    this.storeApp.dispatch('showMenuUserLogged')
  }
}
</script>

<style lang="scss" scoped>
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
