<template>
  <div>
    <PageTitleVue>
      Colaboradores
    </PageTitleVue>

    <ContainerVue>
      <CollabTable :dataTable="dataTable">
        <thead>
          <tr>
            <TableHeadFieldVue>
              <span class="mb-1.5"> Nome </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Celular </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Função </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Serviços Realizados </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </CollabTable>
      <FloatCreateVue
        :link="'new-collab'"
        :menuActive="'Configurações'"
        :icon="'fa-solid fa-user-plus'"
      />
    </ContainerVue>
  </div>
</template>

<script setup>
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import ContainerVue from '@/components/Container.vue'
import TableHeadFieldVue from '@/components/Tables/TableHeadField.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CollabTable from './components/Table.vue'
import CollabService from './services/collab.service'
import { storeApp } from '@/store'
import { onBeforeMount, ref } from 'vue'
import { useToast } from 'vue-toastification'

const appStore = storeApp.state
const dataTable = ref(undefined)
const toast = useToast()

onBeforeMount(async () => {
  appStore.loading = true
  await getCollaborators()
  appStore.loading = false
})

async function getCollaborators () {
  return await CollabService.getAllColaborators()
    .then(res => {
      dataTable.value = res.data.filter(el => el.id !== localStorage.getItem('__uuid'))
    }).catch(err => {
      toast('Algo deu errado ao buscar clientes', {
        toastClassName: 'toast-error'
      })
    })
}

</script>
