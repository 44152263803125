<template>
  <div class="w-full">
    <div class="flex flex-wrap -mx-2">
      <div v-for="permission in store.permissions" :key="permission.id" class="permission-item flex items-start w-full sm:w-1/2 px-2 mb-4">
        <div class="flex items-start shadow-md p-3 rounded-lg w-full hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all">
          <input
            type="checkbox"
            :id="'permission-' + permission.id"
            :value="permission.key"
            class="form-checkbox h-5 w-5 text-indigo-600 ui-checkbox transition cursor-pointer duration-150 ease-in-out mt-auto mb-auto"
            :checked="havePermission(permission.key)"
            @click="addCollect(permission.key)"
          />
          <label :for="'permission-' + permission.id" class="ml-3 leading-5 w-full cursor-pointer">
            <p class="text-xl text-gray-700">
              <label :for="'permission-' + permission.id" class="w-full text-center">
                <small class="">{{ permission.description }}</small> <br>
              </label>
              <small class="text-gray-400 w-full text-left">
                key: <label :for="'permission-' + permission.id"> {{ permission.key }} </label>
              </small>
            </p>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onUpdated, ref } from 'vue'
import { dataCollab } from '../store'

const store = dataCollab.state
const selectedPermissions = ref([])

const props = defineProps({
  register: Boolean
})

onUpdated(() => {
  checkPermissions()
})

function checkPermissions () {
  if (props.register == true) { return }

  store.userPermissions.forEach(element => {
    if (selectedPermissions.value.includes(element.key)) { return }

    return selectedPermissions.value.push(element.key)
  })
}

function addCollect (permission) {
  const index = selectedPermissions.value.indexOf(permission)

  if (index === -1) {
    selectedPermissions.value.push(permission)
  } else {
    selectedPermissions.value.splice(index, 1)
  }

  store.updatePermissions = selectedPermissions.value
}

function havePermission (key) {
  if (props.register == true) { return false }

  return store.userPermissions.some(permission => permission.key === key)
}

</script>

<style scoped>
.font {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 1.1px !important;
}

.permission-item {
  display: flex;
  align-items: center;
}

.ui-checkbox {
  --primary-color: #1677ff;
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  /* checkbox */
  --checkbox-diameter: 20px;
  --checkbox-border-radius: 5px;
  --checkbox-border-color: #d9d9d9;
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  /* checkmark */
  --checkmark-size: 1.2;
}

.ui-checkbox,
.ui-checkbox *,
.ui-checkbox *::before,
.ui-checkbox *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.ui-checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui-checkbox::before {
  top: 40%;
  left: 50%;
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
  -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
}

/* actions */

.ui-checkbox:hover {
  border-color: var(--primary-color);
}

.ui-checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}

.ui-checkbox:checked::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui-checkbox:active:not(:checked)::after {
  -webkit-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: none;
  opacity: 1;
}

</style>
