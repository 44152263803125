import { createStore } from 'vuex'
import SignInComponent from '@/modules/login/layout/SignInComponent.vue'

export const dataAuth = createStore({
  state: () => {
    return {
      activeComponent: SignInComponent,
      auth: {
        email: undefined,
        password: undefined
      },
      register: {
        email: '',
        password: '',
        user_name: '',
        company_name: '',
        indicated_by: null
      },
      forgotPassword: {
        email: '',
        code: ''
      },
      restorePassword: {
        password: '',
        confirm: ''
      }
    }
  },
  getters: {
    getComponentActive: state => state.activeComponent
  },
  mutations: {
    setComponentActive (state, value) {
      state.activeComponent = value
    },
    resetFieldsOfPassword (state) {
      state.forgotPassword.code = ''
      state.forgotPassword.email = ''

      state.restorePassword.password = ''
      state.restorePassword.confirm = ''

      state.register.company_name = ''
      state.register.user_name = ''
      state.register.password = ''
      state.register.email = ''
      state.register.indicated_by = ''
    }
  },
  actions: {
    updateComponentActive ({ commit }, value) {
      commit('setComponentActive', value)
    },
    restorePasswordResetFields ({ commit }) {
      commit('resetFieldsOfPassword')
    }
  }
})
