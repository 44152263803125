<template>
  <div class="p-6 flex min-h-[53vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 top-0">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ props.descriptionTitle }}
              </p>
              <p class="text-gray-500 mb-2">
                Registre os serviços feitos ou produtos vendidos aqui
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                <!-- cliente -->
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="name">
                    Informe o cliente
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :initialValue="store.clients"
                      :data="props.clients"
                      @selected-data="(val) => (store.clients = val)"
                    />
                  </div>

                  <span name="clients" class="text-red-600 text-sm" />
                </div>

                <!-- serviço / produto -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="cellphone">
                    Produto / Serviço <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1 w-full"
                  >
                    <ProductSimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="props.products"
                      @selected-data="(val) => mountItemsToSend(val)"
                    />
                  </div>

                  <span name="products" class="text-red-600 text-sm" />
                </div>

                <!-- forma de pagamento -->
                <div class="md:col-span-2" :key="store.products">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="facebook">
                    Forma de pagamento
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="payments"
                      @selected-data="(val) => (store.method = val)"
                    />
                  </div>
                </div>

                <!-- observação/note -->
                <div class="md:col-span-3">
                  <!-- <label class="text-left flex pl-1" for="facebook">
                    Observação
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.note"
                      name="note"
                      class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    
                    <CleanInputButton
                      @click="store.note = null"
                    />
                  </div> -->

                  <BaseInput
                    v-model="store.note"
                    name="note"
                    :info="'Adicione uma observação ao serviço'"
                    label="Observação"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.note = null"
                      />
                    </template>
                  </BaseInput>
                </div>
              </div>

              <!-- serviços selecionados -->
              <div v-if="dataItems" class="mb-2 w-full p-1">
                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataItems"
                  :key="index"
                >
                  <ArrayItems
                    :item="item?.name"
                    :amount="item?.price"
                    @removeItem="removeItemByDataItems(index)"
                  />
                </div>
              </div>

              <!-- total -->
              <div v-if="total && total.length">
                <div v-if="store?.method?.name" class="w-full flex justify-between font text-gray-400">
                  <span class="w-full mt-auto mb-auto font-semibold">
                    Forma de pagamento:
                  </span>
  
                  <span class="w-full font-semibold">
                    <small>
                      {{ store?.method?.name }}
                    </small>
                  </span>
                </div>

                <div class="w-full flex justify-between font">
                  <span class="w-full mt-auto mb-auto font-semibold">
                    Total:
                  </span>
  
                  <span class="w-full text-2xl text-[#6fb171] font-semibold">
                    {{ calculateTotal(total) }}
                  </span>
                </div>

                <hr>
              </div>

            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArrayItems from './../components/ArrayItems.vue'
import CleanInputButton from '@/utils/CleanInputButton.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import ProductSimpleSelect from './../components/ProductSimpleSelect.vue'
import { dataReleases } from '../store/'
import { defineProps, ref } from 'vue'
import { computed } from 'vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'

const props = defineProps({
  descriptionTitle: String,
  clients: Array,
  products: Array
})

const store = dataReleases.state
const dataItems = computed(() => dataReleases.getters.getDataitens)
const payments = ref([
  {
    name: 'CARTÃO DE CRÉDITO',
    value: 'credit_card'
  },
  {
    name: 'CARTÃO DE DÉBITO',
    value: 'debit_card'
  },
  {
    name: 'PIX',
    value: 'pix'
  },
  {
    name: 'DINHEIRO',
    value: 'money'
  },
  {
    name: 'ASSINATURA',
    value: 'signature'
  },
])

const total = computed(() => store.products)

function calculateTotal(items) {
  // Verifica se o array está vazio
  if (!items || items.length === 0) return "R$ 0,00";

  // Soma os valores convertidos de "R$ ##,##" para números
  const total = items.reduce((acc, item) => {
    const price = parseFloat(
      item.price.replace("R$", "").replace(".", "").replace(",", ".").trim()
    );
    return acc + price;
  }, 0);

  // Formata o resultado de volta para "R$ ##,##"
  return total.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

function mountItemsToSend (item) {
  dataReleases.dispatch('setDataItens', item)
  store.products = store.dataItens
}

function removeItemByDataItems (index) {
  dataItems.value.splice(index, 1)
}

</script>
