import { http, mail } from '@/http'
import { senderServe } from '@/imageServe'
import router from '@/router'
import { storeApp } from '@/store'

const PATH = 'auth/'
const PATH_MAIL = 'api/v1/auth/'

class AuthService {
  async login (request) {
    return http.post(PATH.concat('signin'), request)
  }

  async logout () {
    await http.post(PATH.concat('logout'))
    storeApp.state.accessToken.clear()

    return router.push('/login')
  }

  async register (data) {
    return http.post('app/register', data)
  }

  async forgotPassword (email) {
    return mail.post(PATH_MAIL.concat('verify-email'), email)
  }

  async sendCodeConfirmation (data) {
    return mail.post(PATH_MAIL.concat('validate-code'), data)
  }

  async restorePassword (data) {
    return mail.post(PATH_MAIL.concat('reset-password'), data)
  }
}

export default new AuthService()
