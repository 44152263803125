import { createStore } from 'vuex'

export const dataMoviment = createStore({
  state: () => {
    return {
      haircut: [],
      clients: [],
      products: [],
      total: null,
      filters: {
        date: [],
        fields: {
          name: '',
          product: '',
          client: '',
          method: '',
        },
        applyeds: []
      }
    }
  },
  getters: {
    getClientFilter: state => state.filters.fields.client,
    getNameFilter: state => state.filters.fields.name,
    getProductFilter: state => state.filters.fields.product,
    getApplyedFilters: state => state.filters.applyeds,
    getTotal: state => state.total
  },
  mutations: {
    reset (state) {
      state.haircut = []
    },
    updateMovimentFields (state, payload) {
      Object.assign(state, payload)
    },

    // define os filtros
    setUserFilter (state, value) {
      state.filters.fields.name = value
    },
    setClientFilter (state, value) {
      state.filters.fields.client = value
    },
    setMethodFilter (state, value) {
      state.filters.fields.method = value
    },
    setProductFilter (state, value) {
      state.filters.fields.product = value
    },
    setTotalAmount (state, value) {
      state.total = value
    },

    // limpa todos os filtros
    restoreFilters (state, payoad) {
      state.filters.date = []
      state.filters.fields = {}
    },

    // define os campos ativos de filtro
    setFilteredApplyed (state, { data, index }) {
      const findIndex = state.filters.applyeds.find(
        (el) => el.key === index
      )

      findIndex
        ? findIndex.value = data
        : state.filters.applyeds.push(
          {
            key: index,
            value: data
          }
        )
    },

    // limpa o fitlro informado
    removeItemByKey (state, key) {
      const item = state.filters.applyeds.findIndex(
        (el) => el.key === key
      )

      if (item !== -1) {
        state.filters.applyeds.splice(item, 1)
      }

      if (key === 'Colaborador') state.filters.fields.name = ''
      else if (key === 'Cliente') state.filters.fields.client = ''
      else if (key === 'Produto') state.filters.fields.product = ''
      else if (key === 'Metodo de pagamento') state.filters.fields.method = ''
    }
  },
  actions: {
    updateFilterCollab ({ commit }, data) {
      commit('setUserFilter', data)
      commit('setFilteredApplyed', { data, index: 'Colaborador' })
    },
    updateFilterClient ({ commit }, data) {
      commit('setClientFilter', data)
      commit('setFilteredApplyed', { data, index: 'Cliente' })
    },
    updateFilterMethod ({ commit }, data) {
      commit('setMethodFilter', data)
      commit('setFilteredApplyed', { data, index: 'Metodo de pagamento' })
    },
    updateFilterProducts ({ commit }, data) {
      commit('setProductFilter', data)
      commit('setFilteredApplyed', { data, index: 'Produto' })
    },
    removeSelectedFilter ({ commit }, key) {
      commit('removeItemByKey', key)
    },
    updateTotalAmount ({ commit }, value) {
      commit('setTotalAmount', value)
    }
  }
})
