<template>
  <div class="rounded-lg w-full max-w-md bg-[#21242b]">
    <div
      class="text-white text-left p-4 rounded-tr-md rounded-tl-md bg-[#2b3038]"
    >
      <h2 class="text-xl font-semibold mt-auto mb-auto flex">
        <ScissorsIcon class="h-9 w-9 mr-3" />
        <span class="mt-auto mb-auto">
          Selecione um ou mais serviços
        </span>
      </h2>
    </div>

    <section class="max-h-[65vh] overflow-y-auto">
      <div v-if="dataItems" :class="[dataItems.length == 0 ? 'p-0' : 'p-2']" class="w-full">
        <div
          class="inline-flex flex-wrap"
          v-for="(item, index) in dataItems"
          :key="index"
        >
          <ArrayItems
            :item="item?.name"
            @removeItem="removeItemByDataItems(index)"
          />
        </div>
      </div>

      <div class="p-4 max-h-[65vh] overflow-y-auto" :class="[dataItems.length == 0 ? 'mt-0' : '-mt-4']">
        <ul class="space-y-4">
          <li
            v-for="item in services"
            :key="item.id"
            class="flex items-center p-4 bg-[#2b3038] rounded-lg cursor-pointer hover:bg-[#343a41] transition"
            @click="mountItemsToSend(item)"
          >

            <div class="flex-1">
              <div class="flex text-sm text-gray-400 pt-1 w-full justify-between">
                <div class="text-white font-semibold text-left mt-auto mb-auto">
                  <span class="text-lg">{{ item.name }}</span> <br>
                  <small v-if="item.description" class="font-normal text-[#6f7990]">{{ item.description }}</small>
                </div>
                <div class="text-[#6fb171] font-semibold text-base mt-auto mb-auto min-w-[25%]">
                  {{ formatMoney(item.price) }}
                </div>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script setup>
import ArrayItems from '../components/ArrayItems.vue'
import { ScissorsIcon } from '@heroicons/vue/outline'
import { dataSchedule } from '../store'
import { onMounted, ref, computed } from 'vue'

const store = dataSchedule
const dataItems = ref([])
const informations = dataSchedule.getters

const services = computed(() => informations.getServiceList)

function formatMoney (value) {
  if (typeof value === 'string') {
    value = value.replace(/[^0-9,]/g, '').replace(',', '.')
    value = parseFloat(value)
  }

  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}

function mountItemsToSend (item) {
  dataItems.value.push(item)
  store.dispatch('updateClientScheduleServicesSelected', dataItems)
}

function removeItemByDataItems (index) {
  dataItems.value.splice(index, 1)
}

onMounted(() => {
  if (store.getters.getServicesSelected.length > 0) { dataItems.value = store.getters.getServicesSelected }
})

</script>

<style scoped>
</style>
