<template>
  <div>
    <PageTitleVue> Novo Produtos </PageTitleVue>

    <ContainerVue>
      <Form :descriptionTitle="'Adicionar Produto'">
        <CreateButton
          @click="newProduct"
          :icon="'fa-solid fa-boxes-packing'"
        />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ProductService from '../services/product.service'
import ContainerVue from '@/components/Container.vue'
import Form from '../layouts/Form.vue'
import { useToast } from 'vue-toastification'
import { dataProduct } from '../store'
import { storeApp } from '@/store'
import * as yup from '@/validator'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onMounted } from 'vue'

const appStore = storeApp
const store = dataProduct
const toast = useToast()

const form = yup.object().shape({
  name: yup.string().required().label('Produto').min(3, 'Deve ter no minimo 3 caracteres'),
  amount: yup.string().label('Quantidade'),
  description: yup.string(),
  type: yup.object().required().label('Tipo').nullable(),
  price: yup.string()
    .required('O valor é obrigatório')
    .test(
      'is-greater-than-zero',
      'O valor deve ser maior que zero',
      value => {
        const onlyDigits = value.replace(/[^\d,]/g, '').replace(',', '.')
        return parseFloat(onlyDigits) > 0
      }
    )
})

onMounted(() => {
  store.commit('reset')
})

async function newProduct () {
  appStore.state.loading = true

  const validate = (await validateFormField(form, store.state))
  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false

    console.log(validate, store.state);
    
    return
  }

  if(store.state.type.value == 'service') {
    store.state.amount = null
  }

  await ProductService.register(store.state)
    .then(res => {
      if (res.data.success) {
        toast(res.data.success, {
          toastClassName: 'toast-success'
        })
      }
    }).catch(err => {
      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    }).finally(() => {
      appStore.state.loading = false
    })
}

</script>

<style>

</style>
