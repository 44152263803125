<template>
  <div class="rounded-lg w-full max-w-md bg-[#21242b]">
    <div
      class="text-white text-left p-4 rounded-tr-md rounded-tl-md bg-[#2b3038] flex justify-between"
    >
      <h2 class="text-xl font-semibold mt-auto mb-auto flex">
        <CalendarIcon class="h-9 w-9 mr-3"/>
        <div class="w-full mt-auto mb-auto">
          <label class="font-semibold mt-auto mb-auto">Informe o dia e horário</label><br>
          <div v-if="store.getters.getHourSelected">
            <label class="text-sm text-gray-400 flex">
              <span class="mt-auto mb-auto">
                Horário selecionado: <span class="text-white">{{ selectedDate }} as {{ selectedTime }}</span>
              </span> <CheckIcon class="h-6 w-6 text-[#6fb171] mb-1"/>
            </label>
          </div>
        </div>
      </h2>
    </div>

    <section class="p-2 max-h-[60vh] overflow-y-auto">
      <div class="-mt-4">
        <ServiceCalendar />
      </div>

      <div class="flex justify-around bg-[#2b3038] p-4 -mt-2 text-white">
        <button
          v-for="period in periods"
          :key="period"
          :class="[
            'px-4 py-2 rounded font-semibold w-[30%]',
            selectedPeriod === period ? 'bg-white text-[#2b3038] font-bold' : 'bg-[#343a41]'
          ]"
          @click="selectPeriod(period)"
        >
          {{ period }}
        </button>
      </div>

      <div class="p-4 max-h-[65vh] overflow-y-auto grid grid-cols-3 gap-4">
        <button
          v-for="time in filteredTimes"
          :key="time"
          :disabled="isUnavailable(time)"
          :class="[
            'flex items-center justify-center p-4 rounded-lg font-semibold cursor-pointer transition',
            isUnavailable(time) ? 'bg-[#21242b] border border-[#2b3038] cursor-not-allowed text-gray-400' :
            (selectedTime === time ? 'bg-white text-[#2b3038] font-bold' : 'bg-[#2b3038] text-white hover:bg-[#343a41]')
          ]"
          @click="selectTime(time)"
        >
          {{ time }}
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { CalendarIcon, CheckIcon } from '@heroicons/vue/outline'
import { ref, computed, onMounted } from 'vue'
import { dataSchedule } from '../store'
import ServiceCalendar from '../components/ServiceCalendar.vue'

const informations = dataSchedule.getters
const periods = ['Manhã', 'Tarde', 'Noite']
const selectedPeriod = ref('Manhã')
const selectedTime = ref(null)
const store = dataSchedule
const times = ref([])
const unavailableTimes = computed(() => informations.getUnavailableTimes)

const filteredTimes = computed(() => times.value[selectedPeriod.value])
const selectedDate = computed(() => {
  if (store.getters.getDateSelected) {
    const storedDate = store.getters.getDateSelected
    const [year, month, day] = storedDate.split('-')

    return `${day}/${month}`
  }
})

function isUnavailable (time) {
  return unavailableTimes.value.includes(time)
}

function selectTime (time) {
  if (!isUnavailable(time)) {
    selectedTime.value = time
    store.dispatch('updateClientScheduleHourSelected', selectedTime)
  }
}

function selectPeriod (period) {
  selectedPeriod.value = period
}

onMounted(() => {
  times.value = informations.getOpeningHours

  if (store.getters.getHourSelected) { selectedTime.value = store.getters.getHourSelected }
})

</script>

<style scoped>
button:focus { outline: none; }
</style>
