<template>
  <div class="space-y-4 w-full">
    <!-- Selecionar Barbeiro -->
    <button
      @click="$emit('open-modal', 'barbeiro')"
      class="flex items-center justify-between w-full px-4 py-3 bg-gray-800 rounded-lg text-left text-white hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
    >
      <span class="flex items-center space-x-2">
        <UserCircleIcon class="h-9 w-9 mr-3" />
        <div class="text-white font-semibold text-left mt-auto mb-auto">
          <span class="text-lg">Selecionar barbeiro</span> <br>
          <!-- <small class="font-normal text-[#6f7990]">Escolha um barbeiro para te atender</small> -->
          <small class="font-normal text-[#6f7990]">Escolha por quem será atendido</small>
        </div>
      </span>

      <ChevronDownIcon v-if="! store.getters.getBarberSelected" class="h-7 w-7 text-gray-400 -rotate-90"/>
      <CheckCircleIcon v-else class="h-9 w-9 text-[#6fb171]"/>
    </button>

    <!-- Selecionar Serviço -->
    <button
      @click="$emit('open-modal', 'servico')"
      class="flex items-center justify-between w-full px-4 py-3 bg-gray-800 rounded-lg text-left text-white hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
    >
      <span class="flex items-center space-x-2">
        <ScissorsIcon class="h-9 w-9 mr-3" />
        <div class="text-white font-semibold text-left mt-auto mb-auto">
          <span class="text-lg">Selecionar serviço</span> <br>
          <small class="font-normal text-[#6f7990]">Informe os serviços que deseja</small>
        </div>
      </span>
      <ChevronDownIcon v-if="store.getters.getServicesSelected.length == 0" class="h-7 w-7 text-gray-400 -rotate-90"/>
      <CheckCircleIcon v-else class="h-9 w-9 text-[#6fb171]"/>
    </button>

    <!-- Selecionar Data e Hora -->
    <button
      @click="$emit('open-modal', 'dataHora')"
      class="flex items-center justify-between w-full px-4 py-3 bg-gray-800 rounded-lg text-left text-white hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
    >
      <span class="flex items-center space-x-2">
        <CalendarIcon class="h-9 w-9 mr-3" />
        <div class="text-white font-semibold text-left mt-auto mb-auto">
          <span class="text-lg">Selecionar data e hora</span> <br>
          <small class="font-normal text-[#6f7990]">Escolha o melhor horario para ser atendido</small>
        </div>
      </span>
      <ChevronDownIcon v-if="! store.getters.getHourSelected" class="h-7 w-7 text-gray-400 -rotate-90"/>
      <CheckCircleIcon v-else class="h-9 w-9 text-[#6fb171]"/>
    </button>
  </div>
</template>

<script setup>
import { CalendarIcon, ChevronDownIcon, ScissorsIcon, UserCircleIcon, CheckCircleIcon } from '@heroicons/vue/outline'
import { dataSchedule } from '../store'

const store = dataSchedule

</script>
